import { Link as MuiLink } from "@material-ui/core";
import { ReactElement } from "react";
import { Link as RouterLink } from "react-router-dom";

interface CrosslinkProps {
  to: string;
  count: number;
}

export default function Crosslink(props: CrosslinkProps): ReactElement {
  if (props.count > 0) {
    return (
      <MuiLink component={RouterLink} to={props.to}>
        {props.count}
      </MuiLink>
    );
  } else {
    return <>{props.count}</>;
  }
}
