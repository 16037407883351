import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export function useSearchParam<T>(
  name: string,
  defaultValue: T
): [T, (x: T) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = (searchParams.get(name) as T) || defaultValue;

  const setParam = (value: T) =>
    setSearchParams(
      (params) => {
        if (value) {
          params.set(name, String(value));
        } else {
          params.delete(name);
        }
        return params;
      },
      { replace: true }
    );

  return [value, (value: T) => setParam(value)];
}

export function useSearchParamNumber(
  name: string,
  defaultValue: number = 0
): [number, (x: number) => void] {
  const [param, setParam] = useSearchParam(name, String(defaultValue));

  const number = Number(param);

  const setNumber = (number: number) => setParam(number ? String(number) : "");

  return [number, setNumber];
}

export function useSearchParamArray(
  name: string
): [string[], (x: string[]) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const [values, setValues] = useState<string[]>(searchParams.getAll(name));

  const setParamValues = (newValues: string[]) => {
    setValues(newValues);

    setSearchParams(
      (params) => {
        params.delete(name);

        for (const newValue of newValues) {
          params.append(name, newValue);
        }

        return params;
      },
      { replace: true }
    );
  };

  return [values, setParamValues];
}

export function useLocalStorage(
  name: string,
  defaultValue: string
): [string, (x: string) => void] {
  const value = localStorage.getItem(name) || defaultValue;

  const setValue = (value: string) => localStorage.setItem(name, value);

  return [value, setValue];
}
