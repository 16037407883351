import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import Application from "./Components/Application";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

var root = document.querySelector("#root");

var app = <Application />;

ReactDOM.render(app, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
