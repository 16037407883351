import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { ReactElement } from "react";

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onDismiss: () => void;
}

export default function ConfirmDialog(props: ConfirmDialogProps): ReactElement {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={() => props.onDismiss()}
    >
      <DialogTitle>{props.title}</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={() => props.onConfirm()}>
          Confirm
        </Button>

        <Button color="primary" onClick={() => props.onDismiss()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
