import { CircularProgress, Typography } from "@material-ui/core";
import { ReactElement } from "react";

interface LoadingOverlayProps {
  active: boolean;
  spinner: boolean;
  text: string;
  children: any;
}

export default function LoadingOverlay(
  props: LoadingOverlayProps
): ReactElement {
  return (
    <div style={{ position: "relative" }}>
      {props.active && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            textAlign: "center",
            background: "rgba(0, 0, 0, 0.7)",
            padding: "10px",
            zIndex: 100,
          }}
        >
          {props.spinner && <CircularProgress />}
          <Typography>{props.text}</Typography>
        </div>
      )}
      {props.children}
    </div>
  );
}
