import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import React from "react";
import { ReactElement } from "react";
import { Project } from "../types";
import { formatDateOnly } from "../utils";

interface ProjectInfoDialogProps {
  open: boolean;
  project: Project;
  onClose: () => void;
}

export default function ProjectInfoDialog(
  props: ProjectInfoDialogProps
): ReactElement {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
      <DialogTitle>Project {props.project.id} Details</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextField
              label="Project No"
              value={props.project.code}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Project Name"
              value={props.project.name}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Client Name"
              value={props.project.clientName}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="As of date"
              value={formatDateOnly(props.project.asOfDate)}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Work Types"
              value={props.project.workTypes}
              variant="standard"
              margin="dense"
              fullWidth
              multiline
              minRows={4}
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Evaluation Type"
              value={props.project.evalType}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Guideline"
              value={props.project.guideline}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Project Leader"
              value={props.project.projLeader}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Project Coordinator"
              value={props.project.projCoordinator}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Tech Coordinator"
              value={props.project.techCoordinator}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Comments"
              value={props.project.comment}
              variant="standard"
              margin="dense"
              fullWidth
              multiline
              minRows={13}
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
