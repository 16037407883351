import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { ChangeEvent, ReactElement, useState } from "react";

interface ThemeChooserDialogProps {
  open: boolean;
  value: string;
  onConfirm: (value: string) => void;
  onDismiss: () => void;
}

export default function ThemeChooserDialog(
  props: ThemeChooserDialogProps
): ReactElement {
  const [value, setValue] = useState(props.value);

  const onChange = (event: ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);
  };

  const onConfirm = () => {
    props.onConfirm(value);
  };

  const onDismiss = () => {
    props.onDismiss();
    setValue(props.value);
  };

  return (
    <Dialog open={props.open} onClose={onDismiss} fullWidth maxWidth="xs">
      <DialogTitle>Color Scheme</DialogTitle>
      <DialogContent>
        <FormControl style={{ minWidth: "100%" }}>
          <Select
            variant="standard"
            fullWidth
            value={value}
            onChange={onChange}
          >
            <MenuItem value="demac">D&amp;M</MenuItem>
            <MenuItem value="light">Light</MenuItem>
            <MenuItem value="dark">Dark</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onDismiss}>
          Cancel
        </Button>
        <Button color="primary" onClick={onConfirm}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
