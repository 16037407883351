import { Box } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeView from "@material-ui/lab/TreeView";
import React, { Component } from "react";
import { apiGetFields, apiGetReservoirs } from "../../api";
import {
    ApiCallBasins,
    ApiCallCountries,
    ApiCallGetProjectFields,
    ApiCallGetProjectReservoirs,
    ApiCallSaveProjectProperties,
} from "../../Utils/ApiCalls";
import LoadingOverlay from "../LoadingOverlay";
import CountryNode from "./CountryBasin/CountryNode";

class CountryBasins extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingActive: false,
            countries: [],
            countryBasins: [],
            countryBasinsInfo: [],

            basinFields: [],
            basinReservoirs: [],

            checkedFields: [],
            checkedReservoirs: [],

            selectedProjectId: this.props.selectedProject,
            editingMode: this.props.editingMode,

            expandedNodes: [],
        };

        this.apiCallCountries = this.apiCallCountries.bind(this);
        this.getCountries = this.getCountries.bind(this);
        this.nodeToggle = this.nodeToggle.bind(this);

        this.apiCallBasins = this.apiCallBasins.bind(this);
        this.getBasins = this.getBasins.bind(this);

        this.apiCallBasinFields = this.apiCallBasinFields.bind(this);

        this.apiCallBasinReservoirs = this.apiCallBasinReservoirs.bind(this);

        this.apiCallProjectFields = this.apiCallProjectFields.bind(this);
        this.getProjectFields = this.getProjectFields.bind(this);

        this.apiCallProjectReservoirs = this.apiCallProjectReservoirs.bind(this);
        this.getProjectReservoirs = this.getProjectReservoirs.bind(this);

        this.updateDimensions = this.updateDimensions.bind(this);

        this.fieldCheckedChanged = this.fieldCheckedChanged.bind(this);
        this.reservoirCheckedChanged = this.reservoirCheckedChanged.bind(this);

        this.saveChanges = this.saveChanges.bind(this);

        this.treeViewRef = React.createRef();
    }

    componentDidMount() {
        if (this.state.selectedProjectId === null || this.state.selectedProjectId === 0) {
            // do nothing
        } else {
            this.apiCallCountries();
        }

        window.addEventListener("resize", this.updateDimensions);

        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.editingMode !== prevProps.editingMode ||
            this.props.selectedProject !== prevProps.selectedProject ||
            this.props.refreshString !== prevProps.refreshString
        ) {
            this.setState({
                expandedNodes: [],

                selectedProjectId: this.props.selectedProject,
                editingMode: this.props.editingMode,
                addNewProperties: this.props.addNewProperties,

                countries: [],
                countryBasins: [],
                countryBasinsInfo: [],

                basinFields: [],
                basinReservoirs: [],
            });
        }

        if (
            this.state.editingMode !== prevState.editingMode ||
            this.state.selectedProjectId !== prevState.selectedProjectId ||
            this.props.refreshString !== prevProps.refreshString
        ) {
            if (this.state.selectedProjectId === null || this.state.selectedProjectId === 0) {
                // do nothing
            } else {
                this.apiCallCountries();
            }
        }
    }

    apiCallCountries() {
        this.setState({ loadingActive: true });
        ApiCallCountries(
            this.props.authToken,
            this.state.selectedProjectId,
            this.state.editingMode,
            this.state.addNewProperties,
            this.props.filterText,
            this.getCountries
        );
    }

    getCountries(invalid, data) {
        this.setState({ loadingActive: false });

        if (invalid) {
            console.log("Unathorized");
            return;
        }

        let countries = [];
        let basins = [];

        for (let cnt of data) {
            countries.push(cnt);

            basins[cnt.coCode] = [0];
        }

        this.setState({ countryBasins: basins });
        this.setState({ countries: countries });

        this.apiCallProjectFields(this.state.selectedProjectId);
        this.apiCallProjectReservoirs(this.state.selectedProjectId);
    }

    apiCallBasins(coCode) {
        this.setState({ loadingActive: true });
        ApiCallBasins(
            this.props.authToken,
            coCode,
            this.state.selectedProjectId,
            this.state.editingMode,
            this.props.filterText,
            this.getBasins
        );
    }

    getBasins(invalid, coCode, data) {
        this.setState({ loadingActive: false });

        if (invalid) {
            console.log("Unathorized");
            return;
        }

        let countryBasins = [];

        let basinFields = this.state.basinFields;
        let basinReservoirs = this.state.basinReservoirs;

        let countryBasinsInfo = this.state.countryBasinsInfo;

        for (let basin of data) {
            basin.coCode = coCode;

            countryBasins.push(basin);

            basinFields[basin.barCode] = [0];
            basinReservoirs[basin.barCode] = [0];

            countryBasinsInfo[basin.barCode] = {
                fieldCount: basin.fieldCount,
                reservoirCount: basin.reservoirCount,
                basinHasProperties_Fields: basin.basinHasProperties_Fields,
                basinHasProperties_Reservoirs: basin.basinHasProperties_Reservoirs,
            };
        }

        let basins = this.state.countryBasins;
        basins[coCode] = countryBasins;
        this.setState({ countryBasins: basins });

        this.setState({ basinFields: basinFields });
        this.setState({ basinReservoirs: basinReservoirs });

        this.setState({ countryBasinsInfo: countryBasinsInfo });
    }

    async apiCallBasinFields(barCode, coCode) {
        this.setState({ loadingActive: true });

        const fields = await apiGetFields({
            token: this.props.authToken,
            codeOrNameContains: this.props.filterText,
            barCode: barCode,
            countryCode: coCode,
            projectNo: this.state.editingMode ? undefined : this.state.selectedProjectId,
        });

        let basinFields = this.state.basinFields;
        basinFields[barCode] = fields;

        this.setState({ loadingActive: false, basinFields: basinFields });
    }

    async apiCallBasinReservoirs(barCode, coCode) {
        this.setState({ loadingActive: true });

        const reservoirs = await apiGetReservoirs({
            token: this.props.authToken,
            codeOrNameContains: this.props.filterText,
            barCode: barCode,
            countryCode: coCode,
            projectNo: this.state.editingMode ? undefined : this.state.selectedProjectId,
        });

        let basinReservoirs = this.state.basinReservoirs;
        basinReservoirs[barCode] = reservoirs;

        this.setState({
            loadingActive: false,
            basinReservoirs: basinReservoirs,
        });
    }

    apiCallProjectFields(projectId) {
        this.setState({ loadingActive: true });

        ApiCallGetProjectFields(this.props.authToken, projectId, this.getProjectFields);
    }

    getProjectFields(invalid, data) {
        this.setState({ loadingActive: false });

        if (invalid) {
            console.log("Error fetching project fields");
            return;
        }

        let fields = data.map((d) => d.fieldId);
        let stateFields = [];
        for (let f of fields) stateFields.push({ checked: true, id: f });

        this.setState({ checkedFields: stateFields });
    }

    apiCallProjectReservoirs(projectId) {
        this.setState({ loadingActive: true });

        ApiCallGetProjectReservoirs(this.props.authToken, projectId, this.getProjectReservoirs);
    }

    getProjectReservoirs(invalid, data) {
        this.setState({ loadingActive: false });

        if (invalid) {
            console.log("Error fetching project reservoirs");
            return;
        }

        let reservoirs = data.map((d) => d.reservoirId);
        let stateReservoirs = [];
        for (let r of reservoirs) stateReservoirs.push({ checked: true, id: r });

        this.setState({ checkedReservoirs: stateReservoirs });
    }

    fieldCheckedChanged(checked, fieldId) {
        let fields = this.state.checkedFields;
        let obj = fields.find((o) => o.id.toString() === fieldId.toString());

        if (obj === undefined) {
            fields.push({ checked: checked, id: fieldId });
        } else {
            obj.checked = checked;
        }

        fields = fields.filter((o) => o.checked === true);

        this.setState({ checkedFields: fields });
    }

    reservoirCheckedChanged(checked, reservoirId) {
        let reservoirs = this.state.checkedReservoirs;
        let obj = reservoirs.find((o) => o.id.toString() === reservoirId.toString());

        if (obj === undefined) {
            reservoirs.push({ checked: checked, id: reservoirId });
        } else {
            obj.checked = checked;
        }

        reservoirs = reservoirs.filter((o) => o.checked === true);

        this.setState({ checkedReservoirs: reservoirs });
    }

    saveChanges(onComplete) {
        let fields = this.state.checkedFields;
        let reservoirs = this.state.checkedReservoirs;

        let projectId = this.state.selectedProjectId;

        this.setState({ loadingActive: true });

        ApiCallSaveProjectProperties(
            this.props.authToken,
            projectId,
            fields,
            reservoirs,
            this.saveChangesCallback.bind(this, projectId, fields, reservoirs, onComplete)
        );
    }

    saveChangesCallback(projectId, fields, reservoirs, onComplete, invalid, data) {
        this.setState({ loadingActive: false });

        if (invalid) {
            console.log("Error saving project properties");
            return;
        }

        onComplete(projectId, fields, reservoirs);
    }

    nodeToggle(event, nodeIds) {
        this.setState({ expandedNodes: nodeIds });

        if (nodeIds.length === 0) return;

        let node = nodeIds[0];

        if (node.startsWith("CNT_")) {
            // Country
            let coCode = node.substr(4);

            let basins = this.state.countryBasins;
            if (basins[coCode][0] === 0) {
                this.apiCallBasins(coCode);
            }
        } else if (node.startsWith("FLDBAS_")) {
            // Basin Fields
            //FLDBAS_USA_barcode

            let coCode = node.substr(7, 3);
            let barCode = node.substr(11);

            let basinFields = this.state.basinFields;
            if (basinFields[barCode][0] === 0) {
                this.apiCallBasinFields(barCode, coCode);
            }
        } else if (node.startsWith("RESBAS_")) {
            // Basin Reservoirs
            //RESBAS_USA_barcode
            let coCode = node.substr(7, 3);
            let barCode = node.substr(11);

            let basinReservoirs = this.state.basinReservoirs;
            if (basinReservoirs[barCode][0] === 0) {
                this.apiCallBasinReservoirs(barCode, coCode);
            }
        } else {
            //console.log(nodeIds);
        }
    }

    updateDimensions() {
        let height = window.innerHeight - 380;

        this.setState({ windowHeight: height });
    }

    render() {
        return (
            <LoadingOverlay active={this.state.loadingActive} spinner text="Please wait...">
                <Box maxHeight={this.state.windowHeight} style={{ overflow: "auto" }}>
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        onNodeToggle={this.nodeToggle}
                        ref={this.treeViewRef}
                        expanded={this.state.expandedNodes}
                    >
                        {this.state.countries.map((cnt) => {
                            return (
                                <CountryNode
                                    key={cnt.coCode}
                                    country={cnt}
                                    countryBasinsInfo={this.state.countryBasinsInfo}
                                    countryBasins={this.state.countryBasins}
                                    basinFields={this.state.basinFields}
                                    basinReservoirs={this.state.basinReservoirs}
                                    fieldCheckedChanged={this.fieldCheckedChanged}
                                    reservoirCheckedChanged={this.reservoirCheckedChanged}
                                    checkedFields={this.state.checkedFields}
                                    checkedReservoirs={this.state.checkedReservoirs}
                                    selectedProject={this.props.selectedProject}
                                    editingMode={this.props.editingMode}
                                />
                            );
                        })}
                    </TreeView>
                </Box>
            </LoadingOverlay>
        );
    }
}

export default CountryBasins;
