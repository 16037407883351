import {
    Box,
    Button,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import React, { Component } from "react";
import AlertDialog from "./AlertDialog";
import CountryBasins from "./MainForm/CountryBasins";
import ProjectReports from "./MainForm/ProjectReports";
import ProjectSelect from "./MainForm/ProjectSelect";
import ProjectEditProps from "./ProjectEditProps";
import TablePaginationActions from "./TablePaginationActions";

class MainForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProject: 0,
            editingMode: false,
            addNewProperties: false,

            dialogOpen: false,
            dialogText: "Changes saved successfully.",
            dialogTitle: "Project Properties",

            isUploadResultsDialogOpen: false,
            propertiesImportResultsData: [],
            propertiesImportResultsType: 1,
            propertiesImportResultsCsv: "",

            tablePage: 0,
            rowsPerPage: 16,

            countryBasinsRefreshString: "123",
            projectReportsRefreshString: "123",

            propertiesFilterText: "",
        };

        this.countryBasins = React.createRef();
        this.projectEditProps = React.createRef();
        this.projectList = React.createRef();
        this.projectReports = React.createRef();

        this.saveChanges = this.saveChanges.bind(this);
        this.updateForm = this.updateForm.bind(this);

        this.selectedProjectChanged = this.selectedProjectChanged.bind(this);

        this.editProject = this.editProject.bind(this);
        this.addProjectProperties = this.addProjectProperties.bind(this);
        this.setProjectReports = this.setProjectReports.bind(this);

        this.cancelEditProject = this.cancelEditProject.bind(this);

        this.dialogHandler = this.dialogHandler.bind(this);
        this.showDialog = this.showDialog.bind(this);

        this.previousSelectedProject = this.state.selectedProject;

        this.propertiesImportResults = this.propertiesImportResults.bind(this);
        this.propertiesImportResultsClose = this.propertiesImportResultsClose.bind(this);
        this.propertiesImportTableChangePage = this.propertiesImportTableChangePage.bind(this);

        this.filterPropertiesHandler = this.filterPropertiesHandler.bind(this);
        this.getFilterDisplayInfoPanel = this.getFilterDisplayInfoPanel.bind(this);
        this.removePropertiesFilter = this.removePropertiesFilter.bind(this);
    }

    dialogHandler() {
        this.setState({ dialogOpen: false });
    }

    showDialog() {
        this.setState({ dialogOpen: true });
    }

    saveChanges(e) {
        this.setState({
            propertiesFilterText: "",
        });

        this.countryBasins.current.saveChanges((projectId, checkedFields, checkedReservoirs) => {
            //this.projectEditProps.current.setEditMode(false);

            this.showDialog();

            this.projectList.current.updateProjectInfo(projectId, checkedFields.length, checkedReservoirs.length);
        });
    }

    updateForm(projectId) {
        this.projectList.current.updateProjectInfo(projectId, 1, 1);

        this.setState({
            countryBasinsRefreshString: new Date().toLocaleTimeString(),
        });
    }

    selectedProjectChanged(id, name, hasProperties, historical) {
        //console.log("Selected Project Changed", id);

        let isEditingEnabled = true;

        if (!this.props.isAdmin) {
            if (historical) {
                if (hasProperties) isEditingEnabled = false;
            }
        }

        this.projectEditProps.current.setEditMode(false);
        this.projectEditProps.current.setEditingEnabled(isEditingEnabled);
        this.projectEditProps.current.setCurrentProject(id, id + " " + name);

        this.setState({
            selectedProject: id,
        });
    }

    editProject() {
        this.previousSelectedProject = this.state.selectedProject;

        this.setState({
            editingMode: true,
            addNewProperties: false,
        });
    }

    addProjectProperties() {
        this.previousSelectedProject = this.state.selectedProject;

        this.setState({
            editingMode: true,
            addNewProperties: true,
        });
    }

    cancelEditProject() {
        this.setState({
            editingMode: false,
            propertiesFilterText: "",
        });
    }

    setProjectReports() {
        this.setState({
            projectReportsRefreshString: new Date().toLocaleTimeString(),
        });
    }

    propertiesImportResults(data, excelType) {
        this.setState({
            propertiesImportResultsData: data.uploadResults,
            propertiesImportResultsType: excelType,
            propertiesImportResultsCsv: data.csvResults,
            isUploadResultsDialogOpen: true,
        });
    }

    propertiesImportResultsClose() {
        this.setState({ isUploadResultsDialogOpen: false });
    }

    propertiesImportTableChangePage(event, newPage) {
        this.setState({ tablePage: newPage });
    }

    filterPropertiesHandler(value) {
        this.setState({
            propertiesFilterText: value,
            countryBasinsRefreshString: new Date().toLocaleTimeString(),
        });
    }

    removePropertiesFilter(e) {
        e.preventDefault();
        this.setState({
            propertiesFilterText: "",
            countryBasinsRefreshString: new Date().toLocaleTimeString(),
        });
    }

    getFilterDisplayInfoPanel() {
        let filter = this.state.propertiesFilterText;

        if (!filter || filter.trim().length === 0) return <></>;

        return (
            <p style={{ textAlign: "center" }}>
                <InfoIcon fontSize="small" color="primary" style={{ verticalAlign: "middle" }} /> Displaying properties
                that match text <strong>"{this.state.propertiesFilterText}"</strong>. &nbsp;
                <Link href="#" onClick={this.removePropertiesFilter}>
                    Remove filter
                </Link>
            </p>
        );
    }

    render() {
        return (
            <Container component="main" maxWidth="xl">
                <Dialog
                    open={this.state.isUploadResultsDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">Properties Import Results</DialogTitle>
                    <DialogContent>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {this.state.propertiesImportResultsType === 1 ? "Field Code" : "Reservoir Code"}
                                    </TableCell>
                                    <TableCell>
                                        {this.state.propertiesImportResultsType === 1 ? "Field Name" : "Reservoir Name"}
                                    </TableCell>
                                    <TableCell>Import Success</TableCell>
                                    <TableCell>Message</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.propertiesImportResultsData
                                    .slice(
                                        this.state.tablePage * this.state.rowsPerPage,
                                        this.state.tablePage * this.state.rowsPerPage + this.state.rowsPerPage
                                    )
                                    .map((item) => {
                                        return (
                                            <TableRow key={item.fieldName}>
                                                <TableCell>
                                                    <Typography variant="caption" component="span">
                                                        {this.state.propertiesImportResultsType === 1
                                                            ? item.fieldCode
                                                            : item.reservoirCode}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="caption" component="span">
                                                        {this.state.propertiesImportResultsType === 1
                                                            ? item.fieldName
                                                            : item.reservoirName}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Chip
                                                        size="small"
                                                        label={item.importSuccess ? "True" : "False"}
                                                        icon={item.importSuccess ? <DoneIcon /> : <ErrorIcon />}
                                                        color={item.importSuccess ? "primary" : "secondary"}
                                                    ></Chip>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="caption" component="span">
                                                        {item.importFailureMessage}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>

                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={4}
                                        count={this.state.propertiesImportResultsData.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.tablePage}
                                        onPageChange={this.propertiesImportTableChangePage}
                                        ActionsComponent={TablePaginationActions}
                                    ></TablePagination>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <a
                            href={
                                "data:application/octet-stream," +
                                encodeURIComponent(this.state.propertiesImportResultsCsv) +
                                ""
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            download="results.csv"
                        >
                            <Button color="primary" variant="contained">
                                <span
                                    style={{
                                        textDecoration: "none",
                                        textDecorationStyle: "unset",
                                    }}
                                >
                                    Download Report
                                </span>
                            </Button>
                        </a>

                        <Button color="primary" autoFocus onClick={this.propertiesImportResultsClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <AlertDialog
                    isOpen={this.state.dialogOpen}
                    closeHandler={this.dialogHandler}
                    text={this.state.dialogText}
                    title={this.state.dialogTitle}
                />

                <Box marginTop="3rem" />

                <Grid container spacing={4}>
                    <Grid item xs={3} component={Paper} elevation={0} square style={{ padding: 0 }}>
                        <ProjectSelect
                            ref={this.projectList}
                            authToken={this.props.authToken}
                            selectedProjectChanged={this.selectedProjectChanged}
                        />
                    </Grid>

                    <Grid item xs={9} component={Paper} elevation={4}>
                        <Grid container spacing={2} direction="row" alignItems="center">
                            <Grid item xs={3}>
                                <Typography component="span" variant="subtitle2">
                                    Selected Project Properties
                                </Typography>
                            </Grid>

                            <Grid item xs={9}>
                                <div
                                    style={{
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        textAlign: "right",
                                    }}
                                >
                                    <ProjectEditProps
                                        authToken={this.props.authToken}
                                        ref={this.projectEditProps}
                                        saveChangesHandler={this.saveChanges}
                                        updateFormHandler={this.updateForm}
                                        editProjectHandler={this.editProject}
                                        addProjectPropertiesHandler={this.addProjectProperties}
                                        cancelEditProjectHandler={this.cancelEditProject}
                                        propertiesImportResultsHandler={this.propertiesImportResults}
                                        selectedProject={this.state.selectedProject}
                                        setProjectReportsHandler={this.setProjectReports}
                                        filterPropertiesHandler={this.filterPropertiesHandler}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Divider />

                        {this.getFilterDisplayInfoPanel()}

                        <Box marginBottom={3} />

                        <CountryBasins
                            ref={this.countryBasins}
                            authToken={this.props.authToken}
                            selectedProject={this.state.selectedProject}
                            editingMode={this.state.editingMode}
                            addNewProperties={this.state.addNewProperties}
                            refreshString={this.state.countryBasinsRefreshString}
                            filterText={this.state.propertiesFilterText}
                        />

                        <Box marginBottom={3} />

                        <ProjectReports
                            ref={this.projectReports}
                            authToken={this.props.authToken}
                            selectedProject={this.state.selectedProject}
                            refreshString={this.state.projectReportsRefreshString}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default MainForm;
