import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { MouseEvent, ReactElement } from "react";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

export default function TablePaginationActions(
  props: TablePaginationActionsProps
): ReactElement {
  return (
    <div style={{ flexShrink: 0, marginLeft: "20px" }}>
      <IconButton
        disabled={props.page === 0}
        onClick={(e) => props.onPageChange(e, 0)}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        disabled={props.page === 0}
        onClick={(e) => props.onPageChange(e, props.page - 1)}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
        onClick={(e) => props.onPageChange(e, props.page + 1)}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
        onClick={(e) =>
          props.onPageChange(
            e,
            Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1)
          )
        }
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}
