import {
    Button,
    Checkbox,
    Link,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import React, { Component, Fragment } from "react";
import FieldInfoDialog from "../../FieldInfoDialog";
import TablePaginationActions from "../../TablePaginationActions";

class FieldNodes extends Component {
    constructor(props) {
        super(props);

        let selected = [];
        for (let item of props.checkedFields) {
            if (item.checked) selected[item.id] = true;
        }

        this.state = {
            dialogOpen: false,
            dialogField: {},

            countryBasinsInfo: props.countryBasinsInfo,
            basinFields: props.basinFields,

            tablePage: 0,
            rowsPerPage: 15,

            selectedCheckboxes: selected,

            selectedProject: props.selectedProject,
            editingMode: props.editingMode,
        };

        this.getFieldsNodes = this.getFieldsNodes.bind(this);

        this.getBasinNodeIdForField = this.getBasinNodeIdForField.bind(this);
        this.getFieldNodeId = this.getFieldNodeId.bind(this);

        this.fieldTableChangePage = this.fieldTableChangePage.bind(this);

        this.checkboxCheckChanged = this.checkboxCheckChanged.bind(this);

        this.selectAllFields = this.selectAllFields.bind(this);
        this.invertSelectedFields = this.invertSelectedFields.bind(this);
    }

    getBasinNodeIdForField(basin) {
        return basin === undefined || basin.barCode === undefined
            ? Math.random().toString()
            : "FLDBAS_" + basin.coCode + "_" + basin.barCode.toString();
    }

    getFieldNodeId(field) {
        return field === undefined || field.fieldCode === undefined
            ? Math.random().toString()
            : "FLD_" + field.fieldCode.toString();
    }

    fieldTableChangePage(event, newPage) {
        this.setState({ tablePage: newPage });
    }

    checkboxCheckChanged(event) {
        let id = event.target.id.substr(6);
        let checked = event.target.checked;

        let selected = this.state.selectedCheckboxes;
        selected[id] = checked;
        this.setState({ selectedCheckboxes: selected });

        this.props.fieldCheckedChanged(checked, id);
    }

    selectAllFields(fields) {
        let selected = this.state.selectedCheckboxes;

        for (let field of fields) {
            selected[field.id] = true;
            this.props.fieldCheckedChanged(true, field.id);
        }

        this.setState({ selectedCheckboxes: selected });
    }

    invertSelectedFields(fields) {
        let selected = this.state.selectedCheckboxes;

        for (let field of fields) {
            if (selected[field.id] === true) {
                selected[field.id] = false;
                this.props.fieldCheckedChanged(false, field.id);
            } else {
                selected[field.id] = true;
                this.props.fieldCheckedChanged(true, field.id);
            }
        }

        this.setState({ selectedCheckboxes: selected });
    }

    getFieldsNodes(basin) {
        if (basin.fieldCount === undefined || basin.fieldCount === 0) return <></>;

        let info = this.state.countryBasinsInfo[basin.barCode];
        if (info !== undefined) {
            if (info.fieldCount === 0 && info.reservoirCount === 0) return <></>;
        }

        return (
            <TreeItem
                label={
                    <Fragment>
                        <Typography variant="body2" component="span">
                            Fields
                        </Typography>
                    </Fragment>
                }
                nodeId={this.getBasinNodeIdForField(basin)}
                key={this.getBasinNodeIdForField(basin)}
            >
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Alias</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Comment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.basinFields[basin.barCode]
                            .slice(
                                this.state.tablePage * this.state.rowsPerPage,
                                this.state.tablePage * this.state.rowsPerPage + this.state.rowsPerPage
                            )
                            .map((field) => {
                                return (
                                    <TableRow key={this.getFieldNodeId(field)}>
                                        <TableCell>
                                            <Checkbox
                                                onChange={this.checkboxCheckChanged}
                                                id={"fldcb_" + field.id}
                                                style={{
                                                    padding: "0",
                                                    width: "16px",
                                                    height: "16px",
                                                }}
                                                color="primary"
                                                checked={
                                                    (this.state.selectedCheckboxes[field.id] !== undefined &&
                                                        this.state.selectedCheckboxes[field.id]) ||
                                                    this.state.editingMode === false
                                                }
                                                disabled={this.state.editingMode === false}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption" component="span">
                                                <Link
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            dialogOpen: true,
                                                            dialogField: field,
                                                        });
                                                    }}
                                                >
                                                    {field.code === undefined ? "_" : field.code}
                                                </Link>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption" component="span">
                                                {field.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption" component="span">
                                                {field.alias}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption" component="span">
                                                {field.location}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption" component="span">
                                                {field.comment}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <td
                                colSpan="2"
                                className="MuiTableCell-root MuiTableCell-footer MuiTablePagination-root MuiTableCell-sizeSmall"
                            >
                                <Button
                                    style={{
                                        display: this.state.editingMode === false ? "none" : "initial",
                                    }}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => {
                                        this.selectAllFields(this.state.basinFields[basin.barCode]);
                                    }}
                                >
                                    Select All
                                </Button>

                                <span style={{ padding: "0.2rem" }}>&nbsp;</span>

                                <Button
                                    style={{
                                        display: this.state.editingMode === false ? "none" : "initial",
                                    }}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => {
                                        this.invertSelectedFields(this.state.basinFields[basin.barCode]);
                                    }}
                                >
                                    Invert Selection
                                </Button>
                            </td>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={4}
                                count={this.state.basinFields[basin.barCode].length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.tablePage}
                                onPageChange={this.fieldTableChangePage}
                                ActionsComponent={TablePaginationActions}
                            ></TablePagination>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TreeItem>
        );
    }

    render() {
        return (
            <Fragment>
                <FieldInfoDialog
                    open={this.state.dialogOpen}
                    field={this.state.dialogField}
                    onClose={() => {
                        this.setState({ dialogOpen: false });
                    }}
                />
                {this.getFieldsNodes(this.props.basin)}
            </Fragment>
        );
    }
}

export default FieldNodes;
