import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography,
} from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { ApiCallDeleteDocument, ApiCallGetProjectDocuments } from "../../Utils/ApiCalls";

class ProjectReports extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documents: [],

            isConfirmationOpen: false,
            selectedDocument: {},
        };

        this.onGetDocuments = this.onGetDocuments.bind(this);
        this.confirmationClose = this.confirmationClose.bind(this);

        this.confirmDeleteProjectReport = this.confirmDeleteProjectReport.bind(this);
        this.deleteProjectReport = this.deleteProjectReport.bind(this);
        this.openReport = this.openReport.bind(this);
    }

    confirmationOpen() {
        this.setState({ isConfirmationOpen: true });
    }

    confirmationClose() {
        this.setState({ isConfirmationOpen: false });
    }

    confirmDeleteProjectReport(document) {
        this.setState({ selectedDocument: document });

        this.confirmationOpen();
    }

    deleteProjectReport() {
        this.confirmationClose();

        let documents = this.state.documents;
        let selectedDocument = this.state.selectedDocument;

        documents = documents.filter((e) => e.id !== selectedDocument.id);

        this.setState({
            documents: documents,
        });

        // api call to delete project
        ApiCallDeleteDocument(this.props.authToken, selectedDocument.id);
    }

    openReport(document) {
        window.open(document.href, "_blank");
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.selectedProject !== this.props.selectedProject ||
            prevProps.refreshString !== this.props.refreshString
        ) {
            ApiCallGetProjectDocuments(this.props.authToken, this.props.selectedProject, this.onGetDocuments);
        }
    }

    onGetDocuments(data) {
        let documents = [];

        for (let d of data) {
            documents.push(d);
        }

        this.setState({ documents: documents });
    }

    render() {
        if (this.state.documents.length > 0) {
            return (
                <>
                    <Dialog open={this.state.isConfirmationOpen} fullWidth maxWidth="xs">
                        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
                        <DialogContent>
                            <Box m={3}></Box>

                            <p>
                                Please confirm you want to delete Report{" "}
                                <strong>{this.state.selectedDocument.filename}</strong>
                            </p>

                            <Box m={3}></Box>

                            <Divider />
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" autoFocus onClick={this.confirmationClose}>
                                Cancel
                            </Button>
                            <Button color="primary" autoFocus onClick={this.deleteProjectReport}>
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Box component={Card} bgcolor="primary.light">
                        <CardContent>
                            <Typography variant="subtitle2">Attached Reports</Typography>

                            <Divider />

                            <Box marginBottom={2} />

                            {this.state.documents.map((doc) => {
                                return (
                                    <Fragment key={"fr_" + doc.id}>
                                        <Chip
                                            style={{ marginBottom: "5px" }}
                                            key={doc.id}
                                            size="small"
                                            label={doc.filename}
                                            onClick={() => this.openReport(doc)}
                                            component="a"
                                            clickable
                                            color="primary"
                                            onDelete={() => this.confirmDeleteProjectReport(doc)}
                                        />
                                        &nbsp;
                                    </Fragment>
                                );
                            })}
                        </CardContent>
                    </Box>
                </>
            );
        } else {
            return <></>;
        }
    }
}

export default ProjectReports;
