import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";
import { ReactElement, useEffect, useState } from "react";
import { apiAddUser, apiDeleteUser, apiEditUser, apiGetUsers } from "../api";
import { User, UserType } from "../types";
import AlertDialog from "./AlertDialog";
import ConfirmDialog from "./ConfirmDialog";
import UserDialog from "./UserDialog";

interface UsersProps {
  authToken: string;
}

export default function Users(props: UsersProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [isStoring, setIsStoring] = useState(false);

  const [users, setUsers] = useState<User[]>([]);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User>();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User>();

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isStoring) return;

    setIsLoading(true);
    apiGetUsers(props.authToken)
      .then((data) => {
        setIsLoading(false);
        setUsers(data);
      })
      .catch((e) => {
        setIsLoading(false);
        setUsers([]);

        setErrorMessage(e.message);
      });
  }, [props.authToken, isStoring]);

  const saveUser = (email: string, type: UserType, isActive: boolean) => {
    const user = {
      email: email,
      userType: type,
      userActive: isActive,
    };

    setIsStoring(true);
    if (!userToEdit) {
      apiAddUser(props.authToken, user)
        .then((result) => {
          setIsStoring(false);

          if (result.isValid) {
            setIsEditDialogOpen(false);
          } else {
            setErrorMessage(result.errorMessage);
          }
        })
        .catch((e) => {
          setIsStoring(false);
          setErrorMessage(e.message);
        });
    } else {
      apiEditUser(props.authToken, user)
        .then((result) => {
          setIsStoring(false);

          if (result.isValid) {
            setIsEditDialogOpen(false);
          } else {
            setErrorMessage(result.errorMessage);
          }
        })
        .catch((e) => {
          setIsStoring(false);
          setErrorMessage(e.message);
        });
    }
  };

  const deleteUser = () => {
    if (!userToDelete) return;

    setIsStoring(true);
    apiDeleteUser(props.authToken, userToDelete)
      .then((result) => {
        setIsStoring(false);

        if (result.isValid) {
          setIsDeleteDialogOpen(false);
        } else {
          setErrorMessage(result.errorMessage);
        }
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  return (
    <Container component="main" maxWidth="md" style={{ marginTop: 40 }}>
      <Backdrop open={isLoading || isStoring} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      <Paper elevation={3}>
        <Box p={3}>
          <Box mb={2} style={{ display: "flex" }}>
            <Typography variant="body1" noWrap style={{ flexGrow: 1 }}>
              User Management
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                setUserToEdit(undefined);
                setIsEditDialogOpen(true);
              }}
            >
              Add
            </Button>
          </Box>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                <TableCell>E-mail address</TableCell>
                <TableCell>User type</TableCell>
                <TableCell>Login Enabled</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => {
                return (
                  <TableRow key={row.email}>
                    <TableCell>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                          setUserToEdit(row);
                          setIsEditDialogOpen(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                          setUserToDelete(row);
                          setIsDeleteDialogOpen(true);
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption" component="span">
                        {row.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption" component="span">
                        {row.userType}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        size="small"
                        label={row.userActive ? "True" : "False"}
                        icon={row.userActive ? <DoneIcon /> : <ErrorIcon />}
                        color={row.userActive ? "primary" : "secondary"}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Paper>
      <UserDialog
        open={isEditDialogOpen}
        user={userToEdit}
        onConfirm={saveUser}
        onDismiss={() => setIsEditDialogOpen(false)}
      />
      <ConfirmDialog
        open={isDeleteDialogOpen}
        title="Confirm delete user"
        message={
          "Please confirm you want to delete user " + userToDelete?.email
        }
        onConfirm={deleteUser}
        onDismiss={() => setIsDeleteDialogOpen(false)}
      />
      <AlertDialog
        title="Error"
        text={errorMessage}
        isOpen={Boolean(errorMessage)}
        closeHandler={() => setErrorMessage("")}
      />
    </Container>
  );
}
