import { TableCell, TableSortLabel } from "@material-ui/core";
import { MouseEventHandler, ReactElement } from "react";

interface TableHeaderCellProps<TOrder> {
  title: string;
  order: TOrder;
  orderAsc: TOrder;
  orderDesc: TOrder;
  onOrderChange: (value: TOrder) => any;
}

export default function TableHeaderCell<T>(
  props: TableHeaderCellProps<T>
): ReactElement {
  const clickHandler: MouseEventHandler = () => {
    if (props.order === props.orderAsc) {
      props.onOrderChange(props.orderDesc);
    } else {
      props.onOrderChange(props.orderAsc);
    }
  };

  return (
    <TableCell>
      <TableSortLabel
        active={
          props.order === props.orderAsc || props.order === props.orderDesc
        }
        direction={props.order !== props.orderDesc ? "asc" : "desc"}
        onClick={clickHandler}
        children={props.title}
      />
    </TableCell>
  );
}
