import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { Component } from "react";

class FilterProperties extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            filterText: "",
        };

        this.setFilterText = this.setFilterText.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.filterProperties = this.filterProperties.bind(this);
    }

    setFilterText(e) {
        this.setState({ filterText: e.target.value });
    }

    showDialog() {
        this.setState({
            dialogOpen: true,
        });
    }

    closeDialog() {
        this.setState({
            dialogOpen: false,
        });
    }

    filterProperties() {
        this.closeDialog();

        this.props.filterPropertiesHandler(this.state.filterText);
    }

    render() {
        return (
            <>
                <Dialog open={this.state.dialogOpen} fullWidth maxWidth="xs">
                    <DialogTitle>Filter properties</DialogTitle>

                    <DialogContent>
                        <FormControl fullWidth>
                            <TextField
                                label="Enter value"
                                onChange={this.setFilterText}
                                value={this.state.filterText}
                            ></TextField>
                        </FormControl>

                        <Divider />
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={this.closeDialog}>
                            Cancel
                        </Button>
                        <span style={{ padding: "0.2rem" }}>&nbsp;</span>
                        <Button color="primary" autoFocus onClick={this.filterProperties}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Button size="small" variant="outlined" color="secondary" onClick={this.showDialog}>
                    <SearchIcon />
                </Button>
            </>
        );
    }
}

export default FilterProperties;
