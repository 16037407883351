import {
  Box,
  Button,
  Popover,
  TableCell,
  TextField,
  Typography,
} from "@material-ui/core";
import { ReactElement, useEffect, useState } from "react";
import { formatDateOnly } from "../utils";

interface TableDateCellProps {
  from: string;
  to: string;
  onFromChange: (value: string) => void;
  onToChange: (value: string) => void;
}

export default function TableDateCell(props: TableDateCellProps): ReactElement {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [from, setFrom] = useState(props.from);
  const [to, setTo] = useState(props.to);

  useEffect(() => {
    setFrom(props.from);
  }, [props.from]);

  useEffect(() => {
    setTo(props.to);
  }, [props.to]);

  useEffect(() => {
    const timeout = setTimeout(() => props.onFromChange(from), 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from]);

  useEffect(() => {
    const timeout = setTimeout(() => props.onToChange(to), 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to]);

  const getButtonText = () => {
    if (from) {
      if (to) {
        return formatDateOnly(from) + " - " + formatDateOnly(to);
      } else {
        return "From " + formatDateOnly(from);
      }
    } else {
      if (to) {
        return "To " + formatDateOnly(to);
      } else {
        return "All";
      }
    }
  };

  const onClose = () => {
    setAnchor(null);
  };

  return (
    <TableCell>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        onClick={(e) => setAnchor(e.currentTarget)}
      >
        <Typography variant="caption">{getButtonText()}</Typography>
      </Button>
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={onClose}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Box p={1}>
          <TextField
            fullWidth
            type="date"
            label="From"
            InputLabelProps={{ shrink: true }}
            value={from}
            onChange={(e) => {
              setFrom(e.target.value);
            }}
          />
        </Box>
        <Box p={1}>
          <TextField
            fullWidth
            type="date"
            label="To"
            InputLabelProps={{ shrink: true }}
            value={to}
            onChange={(e) => {
              setTo(e.target.value);
            }}
          />
        </Box>
        <Box p={1}>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </Popover>
    </TableCell>
  );
}
