import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Component, Fragment } from "react";
import {
    ApiCallCheckPropertyCount,
    ApiCallExportFields,
    ApiCallExportReservoirs,
    ApiCallSetProjectDocuments,
    ApiCallUploadFile,
} from "../Utils/ApiCalls";
import FilterProperties from "./FilterProperties";
import LoadingOverlay from "./LoadingOverlay";
import ProjectDocumentSelect from "./ProjectDocumentSelect";

class ProjectEditProps extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProject: props.selectedProject,
            projectId: "",
            editingMode: false,
            addingMode: false,
            editingEnabled: true,
            authToken: props.authToken,

            isUploadDialogOpen: false,
            uploadExcelType: 1,
            uploadFile: null,

            uploadErrorMessage: "",

            loadingActive: false,

            isExportDialogOpen: false,
            exportPropertyType: 1,
            exportErrorMessage: "",

            isAddReportsDialogOpen: false,
        };

        this.saveChanges = this.saveChanges.bind(this);

        this.enableEditingMode = this.enableEditingMode.bind(this);
        this.enableAddingMode = this.enableAddingMode.bind(this);

        this.disableEditingMode = this.disableEditingMode.bind(this);
        this.setEditMode = this.setEditMode.bind(this);

        this.setEditingEnabled = this.setEditingEnabled.bind(this);

        this.showUploadDialog = this.showUploadDialog.bind(this);
        this.closeUploadDialog = this.closeUploadDialog.bind(this);
        this.setUploadExcelType = this.setUploadExcelType.bind(this);
        this.setFileToUpload = this.setFileToUpload.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.uploadFileCallback = this.uploadFileCallback.bind(this);
        this.ErrorMessageComponent = this.ErrorMessageComponent.bind(this);

        this.setCurrentProject = this.setCurrentProject.bind(this);

        this.showExportDialog = this.showExportDialog.bind(this);
        this.closeExportDialog = this.closeExportDialog.bind(this);
        this.setExportPropertyType = this.setExportPropertyType.bind(this);
        this.exportProperties = this.exportProperties.bind(this);
        this.ExportErrorMessageComponent = this.ExportErrorMessageComponent.bind(this);
        this.selectedDocumentsHandler = this.selectedDocumentsHandler.bind(this);
        this.addReportsToProject = this.addReportsToProject.bind(this);
        this.addReportsToProjectComplete = this.addReportsToProjectComplete.bind(this);

        this.showAddReportsDialog = this.showAddReportsDialog.bind(this);
        this.closeAddReportsDialog = this.closeAddReportsDialog.bind(this);
    }

    uploadFile() {
        if (this.state.uploadFile === null) {
            this.setState({ uploadErrorMessage: "No file selected." });
            return;
        }

        this.setState({ uploadErrorMessage: "", loadingActive: true });

        ApiCallUploadFile(
            this.state.authToken,
            this.state.projectId,
            this.state.uploadExcelType,
            this.state.uploadFile,
            this.uploadFileCallback
        );
    }

    uploadFileCallback(invalid, data) {
        if (invalid) {
            this.setState({ uploadErrorMessage: "Error while trying to upload file.", loadingActive: false });
            return;
        }

        this.setState({ uploadErrorMessage: "", loadingActive: false });

        if (data.isValid === false) {
            this.setState({ uploadErrorMessage: data.errorMessage });
            return;
        }

        this.closeUploadDialog();

        this.props.propertiesImportResultsHandler(data, this.state.uploadExcelType);

        this.props.updateFormHandler(this.state.projectId);
    }

    showUploadDialog() {
        this.setState({ isUploadDialogOpen: true, uploadErrorMessage: "" });
    }

    closeUploadDialog() {
        this.setState({ isUploadDialogOpen: false, uploadFile: null });
    }

    setUploadExcelType(event) {
        this.setState({
            uploadExcelType: event.target.value,
        });
    }

    setFileToUpload(event) {
        this.setState({
            uploadFile: event.target.files[0],
        });
    }

    setEditingEnabled(enable) {
        this.setState({
            editingEnabled: enable,
        });
    }

    enableEditingMode() {
        this.setState({ editingMode: true });
        this.props.editProjectHandler();
    }

    enableAddingMode() {
        this.setState({ addingMode: true });
        this.props.addProjectPropertiesHandler();
    }

    disableEditingMode() {
        this.setState({ editingMode: false, addingMode: false });
        this.props.cancelEditProjectHandler();
    }

    setEditMode(edit) {
        if (edit) {
            this.enableEditingMode();
        } else {
            this.disableEditingMode();
        }
    }

    saveChanges() {
        this.props.saveChangesHandler();
    }

    setCurrentProject(projectId, projectName) {
        //console.log("Project Id", project);

        this.setState({ projectId: projectId, selectedProject: projectName });
    }

    ErrorMessageComponent() {
        if (this.state.uploadErrorMessage !== "") {
            return (
                <>
                    <Alert variant="filled" severity="error">
                        {this.state.uploadErrorMessage}
                    </Alert>
                    <div style={{ marginBottom: "2rem" }}></div>
                </>
            );
        } else {
            return <></>;
        }
    }

    ExportErrorMessageComponent() {
        if (this.state.exportErrorMessage !== "") {
            return (
                <>
                    <Alert variant="filled" severity="error">
                        {this.state.exportErrorMessage}
                    </Alert>
                    <div style={{ marginBottom: "2rem" }}></div>
                </>
            );
        } else {
            return <></>;
        }
    }

    // -- region for Export Dialog

    showExportDialog() {
        this.setState({ isExportDialogOpen: true, exportErrorMessage: "" });
    }

    closeExportDialog() {
        this.setState({ isExportDialogOpen: false });
    }

    setExportPropertyType(event) {
        this.setState({
            exportPropertyType: event.target.value,
        });
    }

    exportProperties() {
        let forFields = this.state.exportPropertyType === 1 ? true : false;

        this.setState({ exportErrorMessage: "", loadingActive: true });

        ApiCallCheckPropertyCount(
            this.state.authToken,
            this.state.projectId,
            forFields,
            this.exportPropertiesCallback.bind(this, forFields)
        );
    }

    exportPropertiesCallback(forFields, invalid, data) {
        console.log(data);

        if (invalid) {
            this.setState({ exportErrorMessage: "Error while trying to export properties.", loadingActive: false });
            return;
        }

        this.setState({ exportErrorMessage: "", loadingActive: false });

        if (data === 0) {
            if (forFields) {
                this.setState({ exportErrorMessage: "Project contains no fields." });
            } else {
                this.setState({ exportErrorMessage: "Project contains no reservoirs." });
            }
            return;
        }

        if (forFields) {
            ApiCallExportFields(this.state.projectId);
        } else {
            ApiCallExportReservoirs(this.state.projectId);
        }

        //this.setState({ isExportDialogOpen: false });
    }
    // -- end region

    // -- region for Export Dialog

    showAddReportsDialog() {
        this.setState({ isAddReportsDialogOpen: true });
    }

    closeAddReportsDialog() {
        this.setState({ isAddReportsDialogOpen: false });
    }

    selectedDocumentsHandler(documents) {
        this.setState({
            projectDocuments: documents,
        });
    }

    addReportsToProject() {
        var data = {
            projectId: this.props.selectedProject,
            documentNames: this.state.projectDocuments,
        };

        this.setState({ loadingActive: true });

        ApiCallSetProjectDocuments(this.props.authToken, data, this.addReportsToProjectComplete);
    }

    addReportsToProjectComplete(data) {
        this.setState({ isAddReportsDialogOpen: false, loadingActive: false });
        this.props.setProjectReportsHandler();
    }
    // -- end region

    render() {
        if (!this.state.editingEnabled || this.state.projectId === "") {
            return <Fragment />;
        }

        if (this.state.editingMode || this.state.addingMode) {
            return (
                <>
                    <FilterProperties filterPropertiesHandler={this.props.filterPropertiesHandler} />

                    <span style={{ padding: "0.2rem" }}>&nbsp;</span>

                    <Button
                        onClick={this.saveChanges}
                        size="small"
                        variant="contained"
                        color="secondary"
                        style={{ minWidth: "100px" }}
                    >
                        Save
                    </Button>

                    <span style={{ padding: "0.2rem" }}>&nbsp;</span>

                    <Button
                        onClick={this.disableEditingMode}
                        size="small"
                        variant="contained"
                        color="secondary"
                        style={{ minWidth: "100px" }}
                    >
                        Cancel
                    </Button>
                </>
            );
        } else {
            return (
                <>
                    <Dialog
                        open={this.state.isUploadDialogOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="md"
                    >
                        <LoadingOverlay active={this.state.loadingActive} spinner text="Uploading...">
                            <DialogTitle id="alert-dialog-title">
                                Upload Properties for Project {this.state.selectedProject}
                            </DialogTitle>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <InputLabel id="upload-excel-type">Property Type</InputLabel>
                                    <Select
                                        labelId="upload-excel-type"
                                        id="select-excel-type"
                                        size="small"
                                        fullWidth
                                        value={this.state.uploadExcelType}
                                        onChange={this.setUploadExcelType}
                                    >
                                        <MenuItem value={1}>Fields</MenuItem>
                                        <MenuItem value={2}>Reservoirs</MenuItem>
                                    </Select>
                                </FormControl>

                                <div
                                    style={{
                                        marginTop: "2rem",
                                        marginBottom: "2rem",
                                    }}
                                >
                                    <input type="file" accept=".xlsx,.xltx" onChange={this.setFileToUpload} />
                                </div>

                                <this.ErrorMessageComponent />

                                <Divider />

                                {/* <Link href={process.env.PUBLIC_URL + "/fieldstemplate.xlsx"} target="_blank">Download fields template</Link>
                                    &nbsp;&nbsp;
                                    <Link href={process.env.PUBLIC_URL + "/reservoirstemplate.xlsx"} target="_blank">Download reservoirs template</Link> */}

                                <div
                                    style={{
                                        paddingTop: "1rem",
                                        paddingBottom: "1rem",
                                    }}
                                >
                                    <Grid container>
                                        <Grid item xs={6} elevation={3}>
                                            <Link
                                                href={process.env.PUBLIC_URL + "/fieldstemplate.xlsx"}
                                                target="_blank"
                                            >
                                                Download fields template
                                            </Link>
                                        </Grid>

                                        <Grid item xs={6} elevation={3}>
                                            <Link
                                                href={process.env.PUBLIC_URL + "/reservoirstemplate.xlsx"}
                                                target="_blank"
                                            >
                                                Download reservoirs template
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </div>

                                <Divider />
                            </DialogContent>
                            <DialogActions>
                                <Button color="primary" onClick={this.closeUploadDialog}>
                                    Cancel
                                </Button>
                                <span style={{ padding: "0.2rem" }}>&nbsp;</span>
                                <Button color="primary" autoFocus onClick={this.uploadFile}>
                                    Upload
                                </Button>
                            </DialogActions>
                        </LoadingOverlay>
                    </Dialog>

                    <Dialog open={this.state.isExportDialogOpen} fullWidth maxWidth="md">
                        <LoadingOverlay active={this.state.loadingActive} spinner text="Uploading...">
                            <DialogTitle id="alert-dialog-title">
                                Export Properties for Project {this.state.selectedProject}
                            </DialogTitle>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <InputLabel id="upload-excel-type">Property Type</InputLabel>
                                    <Select
                                        labelId="upload-excel-type"
                                        id="select-excel-type"
                                        size="small"
                                        fullWidth
                                        value={this.state.exportPropertyType}
                                        onChange={this.setExportPropertyType}
                                    >
                                        <MenuItem value={1}>Fields</MenuItem>
                                        <MenuItem value={2}>Reservoirs</MenuItem>
                                    </Select>
                                </FormControl>

                                <div
                                    style={{
                                        paddingTop: "1rem",
                                        paddingBottom: "1rem",
                                    }}
                                >
                                    &nbsp;
                                </div>

                                <this.ExportErrorMessageComponent />

                                <div
                                    style={{
                                        paddingTop: "1rem",
                                        paddingBottom: "1rem",
                                    }}
                                >
                                    &nbsp;
                                </div>

                                <Divider />
                            </DialogContent>

                            <DialogActions>
                                <Button color="primary" onClick={this.closeExportDialog}>
                                    Cancel
                                </Button>
                                <span style={{ padding: "0.2rem" }}>&nbsp;</span>
                                <Button color="primary" autoFocus onClick={this.exportProperties}>
                                    Export
                                </Button>
                            </DialogActions>
                        </LoadingOverlay>
                    </Dialog>

                    <Dialog open={this.state.isAddReportsDialogOpen} fullWidth maxWidth="md">
                        <LoadingOverlay active={this.state.loadingActive} spinner text="Please wait...">
                            <DialogTitle id="alert-dialog-title">
                                Add Reports for Project {this.state.selectedProject}
                            </DialogTitle>
                            <DialogContent>
                                <ProjectDocumentSelect
                                    selectedProject={this.props.selectedProject}
                                    authToken={this.props.authToken}
                                    selectedDocumentsHandler={this.selectedDocumentsHandler}
                                />
                            </DialogContent>

                            <DialogActions>
                                <Button color="primary" onClick={this.closeAddReportsDialog}>
                                    Cancel
                                </Button>
                                <span style={{ padding: "0.2rem" }}>&nbsp;</span>
                                <Button color="primary" autoFocus onClick={this.addReportsToProject}>
                                    Add Reports
                                </Button>
                            </DialogActions>
                        </LoadingOverlay>
                    </Dialog>

                    <FilterProperties filterPropertiesHandler={this.props.filterPropertiesHandler} />
                    <span style={{ padding: "0.5rem" }}>&nbsp;</span>

                    <ButtonGroup>
                        <Button size="small" color="secondary" onClick={this.showExportDialog}>
                            Export...
                        </Button>

                        <Button size="small" color="secondary" onClick={this.showUploadDialog}>
                            Upload...
                        </Button>
                    </ButtonGroup>

                    <span style={{ padding: "0.5rem" }}>&nbsp;</span>

                    <Button size="small" variant="contained" color="secondary" onClick={this.showAddReportsDialog}>
                        Reports...
                    </Button>
                    <span style={{ padding: "0.2rem" }}>&nbsp;</span>

                    <Button onClick={this.enableAddingMode} size="small" variant="contained" color="secondary">
                        Add Properties
                    </Button>
                    <span style={{ padding: "0.2rem" }}>&nbsp;</span>
                    <Button onClick={this.enableEditingMode} size="small" variant="contained" color="secondary">
                        Edit
                    </Button>
                </>
            );
        }
    }
}

export default ProjectEditProps;
