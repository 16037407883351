import {
    Button,
    Checkbox,
    Link,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import React, { Component, Fragment } from "react";
import ReservoirInfoDialog from "../../ReservoirInfoDialog";
import TablePaginationActions from "../../TablePaginationActions";

class ReservoirNodes extends Component {
    constructor(props) {
        super(props);

        let selected = [];
        for (let item of props.checkedReservoirs) {
            if (item.checked) selected[item.id] = true;
        }

        this.state = {
            dialogOpen: false,
            dialogReservoir: {},

            countryBasinsInfo: props.countryBasinsInfo,
            basinReservoirs: props.basinReservoirs,

            tablePage: 0,
            rowsPerPage: 15,

            selectedCheckboxes: selected,

            selectedProject: props.selectedProject,
            editingMode: props.editingMode,
        };

        this.getReservoirNodes = this.getReservoirNodes.bind(this);

        this.reservoirTableChangePage = this.reservoirTableChangePage.bind(this);
        this.checkboxCheckChanged = this.checkboxCheckChanged.bind(this);

        this.selectAllReservoirs = this.selectAllReservoirs.bind(this);
        this.invertSelectedReservoirs = this.invertSelectedReservoirs.bind(this);
    }

    getBasinNodeIdForReservoir(basin) {
        return basin === undefined || basin.barCode === undefined
            ? Math.random().toString()
            : "RESBAS_" + basin.coCode + "_" + basin.barCode.toString();
    }

    getReservoirNodeId(reservoir) {
        return reservoir === undefined || reservoir.code === undefined
            ? Math.random().toString()
            : "RES_" + reservoir.code.toString();
    }

    reservoirTableChangePage(event, newPage) {
        this.setState({ tablePage: newPage });
    }

    checkboxCheckChanged(event) {
        let id = event.target.id.substr(6);
        let checked = event.target.checked;

        let selected = this.state.selectedCheckboxes;
        selected[id] = checked;
        this.setState({ selectedCheckboxes: selected });

        this.props.reservoirCheckedChanged(checked, id);
    }

    selectAllReservoirs(reservoirs) {
        let selected = this.state.selectedCheckboxes;

        for (let reservoir of reservoirs) {
            selected[reservoir.id] = true;
            this.props.reservoirCheckedChanged(true, reservoir.id);
        }

        this.setState({ selectedCheckboxes: selected });
    }

    invertSelectedReservoirs(reservoirs) {
        let selected = this.state.selectedCheckboxes;

        for (let reservoir of reservoirs) {
            if (selected[reservoir.id] === true) {
                selected[reservoir.id] = false;
                this.props.reservoirCheckedChanged(false, reservoir.id);
            } else {
                selected[reservoir.id] = true;
                this.props.reservoirCheckedChanged(true, reservoir.id);
            }
        }

        this.setState({ selectedCheckboxes: selected });
    }

    getReservoirNodes(basin) {
        if (basin.reservoirCount === undefined || basin.reservoirCount === 0) return <></>;

        let info = this.state.countryBasinsInfo[basin.barCode];
        if (info !== undefined) {
            if (info.reservoirCount === 0 && info.reservoirCount === 0) return <></>;
        }

        return (
            <TreeItem
                label={
                    <Fragment>
                        <Typography variant="body2" component="span">
                            Reservoirs
                        </Typography>
                    </Fragment>
                }
                nodeId={this.getBasinNodeIdForReservoir(basin)}
                key={this.getBasinNodeIdForReservoir(basin)}
            >
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Alias</TableCell>
                            <TableCell>Product</TableCell>
                            <TableCell>Comment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.basinReservoirs[basin.barCode]
                            .slice(
                                this.state.tablePage * this.state.rowsPerPage,
                                this.state.tablePage * this.state.rowsPerPage + this.state.rowsPerPage
                            )
                            .map((reservoir) => {
                                return (
                                    <TableRow key={this.getReservoirNodeId(reservoir)}>
                                        <TableCell>
                                            <Checkbox
                                                onChange={this.checkboxCheckChanged}
                                                id={"fldcb_" + reservoir.id}
                                                style={{
                                                    padding: "0",
                                                    width: "16px",
                                                    height: "16px",
                                                }}
                                                color="primary"
                                                checked={
                                                    (this.state.selectedCheckboxes[reservoir.id] !== undefined &&
                                                        this.state.selectedCheckboxes[reservoir.id]) ||
                                                    this.state.editingMode === false
                                                }
                                                disabled={this.state.editingMode === false}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption" component="span">
                                                <Link
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            dialogOpen: true,
                                                            dialogReservoir: reservoir,
                                                        });
                                                    }}
                                                >
                                                    {reservoir.code === undefined ? "_" : reservoir.code}
                                                </Link>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption" component="span">
                                                {reservoir.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption" component="span">
                                                {reservoir.alias}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption" component="span">
                                                {reservoir.product}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption" component="span">
                                                {reservoir.comment}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <td
                                colSpan="2"
                                className="MuiTableCell-root MuiTableCell-footer MuiTablePagination-root MuiTableCell-sizeSmall"
                            >
                                <Button
                                    style={{
                                        display: this.state.editingMode === false ? "none" : "initial",
                                    }}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => {
                                        this.selectAllReservoirs(this.state.basinReservoirs[basin.barCode]);
                                    }}
                                >
                                    Select All
                                </Button>

                                <span style={{ padding: "0.2rem" }}>&nbsp;</span>

                                <Button
                                    style={{
                                        display: this.state.editingMode === false ? "none" : "initial",
                                    }}
                                    size="small"
                                    variant="outlined"
                                    onClick={() => {
                                        this.invertSelectedReservoirs(this.state.basinReservoirs[basin.barCode]);
                                    }}
                                >
                                    Invert Selection
                                </Button>
                            </td>

                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={4}
                                count={this.state.basinReservoirs[basin.barCode].length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.tablePage}
                                onPageChange={this.reservoirTableChangePage}
                                ActionsComponent={TablePaginationActions}
                            ></TablePagination>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TreeItem>
        );
    }

    render() {
        return (
            <Fragment>
                <ReservoirInfoDialog
                    open={this.state.dialogOpen}
                    reservoir={this.state.dialogReservoir}
                    onClose={() => {
                        this.setState({ dialogOpen: false });
                    }}
                />
                {this.getReservoirNodes(this.props.basin)}
            </Fragment>
        );
    }
}

export default ReservoirNodes;
