import {
  Checkbox,
  Popper,
  PopperProps,
  TableCell,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ReactElement, useEffect, useState } from "react";

interface Option {
  code: string;
  name: string;
}

interface TableSelectCellProps {
  options: Option[];
  value: string[];
  onChange: (value: string[]) => void;
}

function CustomPopper(props: PopperProps) {
  return <Popper {...props} style={{ width: "fit-content" }} />;
}

export default function TableSelectCell(
  props: TableSelectCellProps
): ReactElement {
  const [value, setValue] = useState<Option[]>([]);

  useEffect(() => {
    setValue(props.options.filter((x) => props.value.includes(x.code)));
  }, [props.options, props.value]);

  return (
    <TableCell>
      <Autocomplete
        multiple
        fullWidth
        disableCloseOnSelect
        size="small"
        options={props.options}
        value={value}
        getOptionLabel={(option) => option.name}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox size="small" checked={selected} />
            <Typography variant="caption">{option.name}</Typography>
          </>
        )}
        renderInput={(params) => <TextField {...params} />}
        renderTags={(value) => value.length + " selected"}
        onChange={(_, value) => {
          setValue(value);
          props.onChange(value.map((x) => x.code));
        }}
        PopperComponent={CustomPopper}
      />
    </TableCell>
  );
}
