import { createTheme, Theme } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const lightTheme: Theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: blue[400],
    },
  },
});

const darkTheme: Theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: blue[400],
    },
  },
});

const demacTheme: Theme = createTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: {
      paper: "rgba(247, 244, 231, 1)",
      default: "rgba(247, 240, 210, 1)",
    },
    primary: {
      light: "rgba(230, 214, 159, 1)",
      main: "rgba(184, 160, 85, 1)",
      dark: "rgba(123, 108, 59, 1)",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(29, 160, 79, 1)",
      main: "rgba(0, 98, 37, 1)",
      dark: "rgba(0, 61, 23, 1)",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
});

export default function getTheme(themeName: string): Theme {
  switch (themeName) {
    case "light":
      return lightTheme;
    case "dark":
      return darkTheme;
    case "demac":
    default:
      return demacTheme;
  }
}
