import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { ReactElement, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthProvider from "../ADLogin/AuthProvider";
import { apiGetCurrentUser } from "../api";
import { useLocalStorage } from "../hooks";
import getTheme from "../theme";
import Fields from "./Fields";
import Header from "./Header";
import LegacySearch from "./LegacySearch";
import Login from "./Login";
import MainForm from "./MainForm";
import Projects from "./Projects";
import Reservoirs from "./Reservoirs";
import ThemeChooserDialog from "./ThemeChooserDialog";
import Unauthorized from "./Unauthorized";
import Users from "./Users";

interface ApplicationProps {
  token: string;
  account: any;
  onSignIn: () => void;
  onSignOut: () => void;
}

function Application(props: ApplicationProps): ReactElement {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [theme, setTheme] = useLocalStorage("app.theme", "demac");
  const [themeDialogOpen, setThemeDialogOpen] = useState(false);

  useEffect(() => {
    if (props.token) {
      apiGetCurrentUser(props.token)
        .then((data) => {
          setIsAdmin(data.isAdmin);
          setIsLocked(data.isLocked);
        })
        .catch(() => {
          console.error("Error fetching current user info.");
        });
    }
  }, [props.token]);

  const getContent = () => {
    if (!props.account || !props.token) {
      return <Login onLoginClick={props.onSignIn} />;
    } else if (isLocked) {
      return <Unauthorized onLogoutClick={props.onSignOut} />;
    } else {
      return (
        <Routes>
          <Route
            path="/"
            element={<MainForm authToken={props.token} isAdmin={isAdmin} />}
          />
          <Route
            path="/search"
            element={<LegacySearch authToken={props.token} />}
          />
          <Route
            path="/projects"
            element={<Projects authToken={props.token} />}
          />
          <Route path="/fields" element={<Fields authToken={props.token} />} />
          <Route
            path="/reservoirs"
            element={<Reservoirs authToken={props.token} />}
          />
          <Route path="/users" element={<Users authToken={props.token} />} />
        </Routes>
      );
    }
  };

  return (
    <MuiThemeProvider theme={getTheme(theme)}>
      <CssBaseline />
      <BrowserRouter>
        <Header
          isSignedIn={props.account}
          isAdmin={isAdmin}
          onChangeColorSchemeClick={() => setThemeDialogOpen(true)}
          onLogoutClick={props.onSignOut}
        />
        {getContent()}
        <ThemeChooserDialog
          open={themeDialogOpen}
          value={theme}
          onConfirm={(newTheme) => {
            setTheme(newTheme);
            setThemeDialogOpen(false);
          }}
          onDismiss={() => {
            setThemeDialogOpen(false);
          }}
        />
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default AuthProvider(Application);
