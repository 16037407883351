export interface CurrentUser {
  isAdmin: boolean;
  isLocked: boolean;
}

export interface Country {
  coCode: string;
  countryName: string;
  countOfBARCode: number;
  hasFieldsOrReservoirs: boolean;
}

export interface EvalType {
  code: string;
  name: string;
}

export interface Guideline {
  code: string;
  name: string;
}

export interface WorkType {
  code: string;
  name: string;
}

export enum ProjectOrder {
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  CODE_ASC = "CODE_ASC",
  CODE_DESC = "CODE_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  ASOFDATE_ASC = "ASOFDATE_ASC",
  ASOFDATE_DESC = "ASOFDATE_DESC",
  DIVISION_ASC = "DIVISION_ASC",
  DIVISION_DESC = "DIVISION_DESC",
  PROJLEADER_ASC = "PROJLEADER_ASC",
  PROJLEADER_DESC = "PROJLEADER_DESC",
  PROJCOORDINATOR_ASC = "PROJCOORDINATOR_ASC",
  PROJCOORDINATOR_DESC = "PROJCOORDINATOR_DESC",
  TECHCOORDINATOR_ASC = "TECHCOORDINATOR_ASC",
  TECHCOORDINATOR_DESC = "TECHCOORDINATOR_DESC",
  CLIENTNAME_ASC = "CLIENTNAME_ASC",
  CLIENTNAME_DESC = "CLIENTNAME_DESC",
  WORKTYPES_ASC = "WORKTYPES_ASC",
  WORKTYPES_DESC = "WORKTYPES_DESC",
  EVALTYPE_ASC = "EVALTYPE_ASC",
  EVALTYPE_DESC = "EVALTYPE_DESC",
  GUIDELINE_ASC = "GUIDELINE_ASC",
  GUIDELINE_DESC = "GUIDELINE_DESC",
}

export interface Project {
  id: number;
  code: string;
  name: string;
  asOfDate: string;
  division: string;
  projLeader: string;
  projCoordinator: string;
  techCoordinator: string;
  clientCode: string;
  clientName: string;
  workTypes: string;
  evalType: string;
  guideline: string;
  comment: string;
  status: string;
  countries: string;
  fieldCount: number;
  reservoirCount: number;
}

export const EmptyProject: Project = {
  id: 0,
  code: "",
  name: "",
  asOfDate: "",
  division: "",
  projLeader: "",
  projCoordinator: "",
  techCoordinator: "",
  clientCode: "",
  clientName: "",
  workTypes: "",
  evalType: "",
  guideline: "",
  comment: "",
  status: "",
  countries: "",
  fieldCount: 0,
  reservoirCount: 0,
};

export enum FieldOrder {
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  CODE_ASC = "CODE_ASC",
  CODE_DESC = "CODE_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  ALIAS_ASC = "ALIAS_ASC",
  ALIAS_DESC = "ALIAS_DESC",
  COUNTRY_NAME_ASC = "COUNTRY_NAME_ASC",
  COUNTRY_NAME_DESC = "COUNTRY_NAME_DESC",
  BAR_NAME_ASC = "BAR_NAME_ASC",
  BAR_NAME_DESC = "BAR_NAME_DESC",
  LOCATION_ASC = "LOCATION_ASC",
  LOCATION_DESC = "LOCATION_DESC",
  ADDED_BY_ASC = "ADDED_BY_ASC",
  ADDED_BY_DESC = "ADDED_BY_DESC",
  ADDED_ON_ASC = "ADDED_ON_ASC",
  ADDED_ON_DESC = "ADDED_ON_DESC",
  UPDATED_BY_ASC = "UPDATED_BY_ASC",
  UPDATED_BY_DESC = "UPDATED_BY_DESC",
  UPDATED_ON_ASC = "UPDATED_ON_ASC",
  UPDATED_ON_DESC = "UPDATED_ON_DESC",
  IS_DELETED_ASC = "IS_DELETED_ASC",
  IS_DELETED_DESC = "IS_DELETED_DESC",
}

export interface Field {
  id: number;
  code: string;
  name: string;
  alias: string;
  comment: string;
  countryCode: string;
  countryName: string;
  barName: string;
  location: string;
  projectCount: number;
  addedBy: string;
  addedOn: string;
  updatedBy: string;
  updatedOn: string;
  isDeleted: boolean;
}

export const EmptyField: Field = {
  id: 0,
  code: "",
  name: "",
  alias: "",
  comment: "",
  countryCode: "",
  countryName: "",
  barName: "",
  location: "",
  projectCount: 0,
  addedBy: "",
  addedOn: "",
  updatedBy: "",
  updatedOn: "",
  isDeleted: false,
};

export enum ReservoirOrder {
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  CODE_ASC = "CODE_ASC",
  CODE_DESC = "CODE_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  ALIAS_ASC = "ALIAS_ASC",
  ALIAS_DESC = "ALIAS_DESC",
  COUNTRY_NAME_ASC = "COUNTRY_NAME_ASC",
  COUNTRY_NAME_DESC = "COUNTRY_NAME_DESC",
  BAR_NAME_ASC = "BAR_NAME_ASC",
  BAR_NAME_DESC = "BAR_NAME_DESC",
  ERA_ASC = "ERA_ASC",
  ERA_DESC = "ERA_DESC",
  LITHOLOGY_ASC = "LITHOLOGY_ASC",
  LITHOLOGY_DESC = "LITHOLOGY_DESC",
  PERIOD_ASC = "PERIOD_ASC",
  PERIOD_DESC = "PERIOD_DESC",
  PRODUCT_ASC = "PRODUCT_ASC",
  PRODUCT_DESC = "PRODUCT_DESC",
  ADDED_BY_ASC = "ADDED_BY_ASC",
  ADDED_BY_DESC = "ADDED_BY_DESC",
  ADDED_ON_ASC = "ADDED_ON_ASC",
  ADDED_ON_DESC = "ADDED_ON_DESC",
  UPDATED_BY_ASC = "UPDATED_BY_ASC",
  UPDATED_BY_DESC = "UPDATED_BY_DESC",
  UPDATED_ON_ASC = "UPDATED_ON_ASC",
  UPDATED_ON_DESC = "UPDATED_ON_DESC",
  IS_DELETED_ASC = "IS_DELETED_ASC",
  IS_DELETED_DESC = "IS_DELETED_DESC",
}

export interface Reservoir {
  id: number;
  code: string;
  name: string;
  alias: string;
  comment: string;
  countryCode: string;
  countryName: string;
  barName: string;
  era: string;
  lithology: string;
  period: string;
  product: string;
  projectCount: number;
  addedBy: string;
  addedOn: string;
  updatedBy: string;
  updatedOn: string;
  isDeleted: boolean;
}

export const EmptyReservoir: Reservoir = {
  id: 0,
  code: "",
  name: "",
  alias: "",
  comment: "",
  countryCode: "",
  countryName: "",
  barName: "",
  era: "",
  lithology: "",
  period: "",
  product: "",
  projectCount: 0,
  addedBy: "",
  addedOn: "",
  updatedBy: "",
  updatedOn: "",
  isDeleted: false,
};

export interface Report {
  reportName: string;
  reportLink: string;
}

export enum UserType {
  ADMIN = "ADMIN",
  USER = "USER",
}

export interface User {
  email: string;
  userType: UserType;
  userActive: boolean;
}

export interface UserActionResult {
  isValid: string;
  errorMessage: string;
}
