import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CSSProperties, ReactElement, useEffect, useState } from "react";
import { apiGetFields } from "../api";
import { Field, FieldOrder } from "../types";

interface SelectFieldProps {
  style?: CSSProperties;
  authToken: string;
  fieldId: number;
  onChange: (fieldId: number) => void;
}

export default function SelectField(props: SelectFieldProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [fields, setFields] = useState<Field[]>([]);
  const [selected, setSelected] = useState<Field | null>(null);

  useEffect(() => {
    if (!props.fieldId && !isOpen) return;

    // We already have this field, no need to (re)load
    if (fields.some((x) => x.id === props.fieldId)) return;

    const timeout = setTimeout(() => {
      setIsLoading(true);
      apiGetFields({
        token: props.authToken,
        ids: !isOpen && !userInput ? [props.fieldId] : undefined,
        codeOrNameStartsWith: userInput,
        order: FieldOrder.NAME_ASC,
        take: 100,
      })
        .then((data) => {
          setIsLoading(false);
          setFields(data);
        })
        .catch(() => {
          setIsLoading(false);
          setFields([]);
        });
    }, 500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authToken, props.fieldId, isOpen, userInput]);

  useEffect(() => {
    setSelected(fields.find((x) => x.id === props.fieldId) || null);
  }, [props.fieldId, fields]);

  return (
    <Autocomplete
      size="small"
      loading={isLoading}
      options={fields}
      value={selected}
      getOptionLabel={(option) => option.name + ", " + option.code}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Field"
          placeholder="Filter by field"
          InputLabelProps={{ shrink: true }}
        />
      )}
      style={props.style}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onChange={(_, value) => {
        setSelected(value);
        props.onChange(value?.id || 0);
      }}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          setUserInput(value);
        }
      }}
    />
  );
}
