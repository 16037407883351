import {
    Box,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Tab,
    Table,
    TableFooter,
    TablePagination,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { Component, Fragment } from "react";
import { ApiCallGetProjects } from "../../Utils/ApiCalls";
import LoadingOverlay from "../LoadingOverlay";
import TablePaginationActions from "../TablePaginationActions";

class ProjectSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabValue: 0,
            loadingActive: false,
            activeProjects: [],
            historicalProjects: [],

            selectedProject: null,
            selectedProjectName: "",
            selectedProjectHasProperties: false,
            selectedProjectIsHistorical: false,

            rowsPerPage: 25,
            activeProjectsCurrentPage: 0,
            historicalProjectsCurrentPage: 0,

            activeProjectsFilter: "",
            historicalProjectsFilter: "",
        };

        this.handleTabChange = this.handleTabChange.bind(this);

        this.getProjects = this.getProjects.bind(this);

        this.apiCallProjects = this.apiCallProjects.bind(this);

        this.handleProjectListClick = this.handleProjectListClick.bind(this);
        this.refreshSelectedProject = this.refreshSelectedProject.bind(this);

        this.updateProjectInfo = this.updateProjectInfo.bind(this);

        this.updateDimensions = this.updateDimensions.bind(this);

        this.activeProjectsChangePage = this.activeProjectsChangePage.bind(this);
        this.historicalProjectsChangePage = this.historicalProjectsChangePage.bind(this);

        this.setActiveProjectsFilter = this.setActiveProjectsFilter.bind(this);
        this.setHistoricalProjectsFilter = this.setHistoricalProjectsFilter.bind(this);
    }

    apiCallProjects() {
        this.setState({ loadingActive: true });

        ApiCallGetProjects(this.props.authToken, this.getProjects);
    }

    getProjects(invalid, data) {
        this.setState({ loadingActive: false });

        if (invalid) {
            console.log("Error fetching projects");
            return;
        }

        let projects = [];
        let historical = [];

        for (let elm of data.projects) {
            //projNo, projName
            if (elm.historical) {
                historical.push(elm);
            } else {
                projects.push(elm);
            }
        }

        //console.log("PROJECT_READ", userAdmin, userLocked);

        // let activeProject = projects[1].id;
        // let hasProperties = projects[1].hasProperties;

        this.setState({
            activeProjects: projects,
            historicalProjects: historical,
        });

        // this.props.selectedProjectChanged(activeProject, hasProperties, false);
    }

    handleTabChange(event, newValue) {
        this.setState({
            tabValue: newValue,
        });
    }

    componentDidMount() {
        this.apiCallProjects();

        window.addEventListener("resize", this.updateDimensions);

        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    handleProjectListClick(id, name, hasProperties, isHistorical) {
        this.setState({
            selectedProject: id,
            selectedProjectName: name,
            selectedProjectHasProperties: hasProperties,
            selectedProjectIsHistorical: isHistorical,
        });

        this.props.selectedProjectChanged(id, name, hasProperties, isHistorical);
    }

    refreshSelectedProject() {
        let id = this.state.selectedProject;
        let name = this.state.selectedProjectName;
        let hasProperties = this.state.selectedProjectHasProperties;
        let isHistorical = this.state.selectedProjectIsHistorical;

        this.props.selectedProjectChanged(id, name, hasProperties, isHistorical);
    }

    updateProjectInfo(projectId, fieldCount, reservoirCount) {
        let projects = this.state.activeProjects;
        let historicalProjects = this.state.historicalProjects;

        for (let p of projects) {
            if (p.id === projectId) {
                p.hasProperties = fieldCount > 0 || reservoirCount > 0;
                break;
            }
        }

        for (let p of historicalProjects) {
            if (p.id === projectId) {
                p.hasProperties = fieldCount > 0 || reservoirCount > 0;
                break;
            }
        }

        this.setState({
            activeProjects: projects,
            historicalProjects: historicalProjects,
        });
    }

    updateDimensions() {
        let height = window.innerHeight - 330;

        this.setState({ windowHeight: height });
    }

    historicalProjectsChangePage(event, newPage) {
        this.setState({ historicalProjectsCurrentPage: newPage });
    }

    activeProjectsChangePage(event, newPage) {
        this.setState({ activeProjectsCurrentPage: newPage });
    }

    setActiveProjectsFilter(e) {
        this.setState({
            activeProjectsFilter: e.target.value,
            activeProjectsCurrentPage: 0,
        });
    }

    setHistoricalProjectsFilter(e) {
        this.setState({
            historicalProjectsFilter: e.target.value,
            historicalProjectsCurrentPage: 0,
        });
    }

    render() {
        return (
            <Fragment>
                <Tabs variant="fullWidth" value={this.state.tabValue} onChange={this.handleTabChange}>
                    <Tab label="Active" />
                    <Tab label="Historical" />
                </Tabs>

                <LoadingOverlay active={this.state.loadingActive} spinner text="Please wait...">
                    <TabPanel value={this.state.tabValue} index={0}>
                        <Box m={1}>
                            <TextField
                                fullWidth
                                label="Filter projects"
                                size="small"
                                variant="outlined"
                                margin="dense"
                                value={this.state.activeProjectsFilter}
                                onChange={this.setActiveProjectsFilter}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                        <Box maxHeight={this.state.windowHeight} style={{ overflow: "auto" }}>
                            <List dense={true}>
                                {this.state.activeProjects
                                    .filter(
                                        (e) =>
                                            e.name
                                                .toLowerCase()
                                                .includes(this.state.activeProjectsFilter.toLowerCase()) ||
                                            e.id.includes(this.state.activeProjectsFilter)
                                    )
                                    .slice(
                                        this.state.activeProjectsCurrentPage * this.state.rowsPerPage,
                                        this.state.activeProjectsCurrentPage * this.state.rowsPerPage +
                                            this.state.rowsPerPage
                                    )
                                    .map((pr) => {
                                        return (
                                            <ListItem
                                                onClick={() =>
                                                    this.handleProjectListClick(pr.id, pr.name, pr.hasProperties, false)
                                                }
                                                key={pr.id}
                                                button
                                                selected={pr.id === this.state.selectedProject}
                                            >
                                                <ListItemPrimarySecondary
                                                    primary={pr.name}
                                                    secondary={pr.id}
                                                    element={pr}
                                                />
                                            </ListItem>
                                        );
                                    })}
                            </List>
                        </Box>

                        <Table size="small">
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={6}
                                        count={
                                            this.state.activeProjects.filter(
                                                (e) =>
                                                    e.name
                                                        .toLowerCase()
                                                        .includes(this.state.activeProjectsFilter.toLowerCase()) ||
                                                    e.id.includes(this.state.activeProjectsFilter)
                                            ).length
                                        }
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.activeProjectsCurrentPage}
                                        onPageChange={this.activeProjectsChangePage}
                                        ActionsComponent={TablePaginationActions}
                                    ></TablePagination>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TabPanel>

                    <TabPanel value={this.state.tabValue} index={1}>
                        <Box m={1}>
                            <TextField
                                fullWidth
                                label="Filter projects"
                                size="small"
                                variant="outlined"
                                margin="dense"
                                value={this.state.historicalProjectsFilter}
                                onChange={this.setHistoricalProjectsFilter}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                        <Box maxHeight={this.state.windowHeight} style={{ overflow: "auto" }}>
                            <List dense={true}>
                                {this.state.historicalProjects
                                    .filter(
                                        (e) =>
                                            e.name
                                                .toLowerCase()
                                                .includes(this.state.historicalProjectsFilter.toLowerCase()) ||
                                            e.id.includes(this.state.historicalProjectsFilter)
                                    )
                                    .slice(
                                        this.state.historicalProjectsCurrentPage * this.state.rowsPerPage,
                                        this.state.historicalProjectsCurrentPage * this.state.rowsPerPage +
                                            this.state.rowsPerPage
                                    )
                                    .map((pr) => {
                                        return (
                                            <ListItem
                                                onClick={() =>
                                                    this.handleProjectListClick(pr.id, pr.name, pr.hasProperties, true)
                                                }
                                                key={pr.id}
                                                button
                                                selected={pr.id === this.state.selectedProject}
                                            >
                                                <ListItemPrimarySecondary
                                                    primary={pr.name}
                                                    secondary={pr.id}
                                                    element={pr}
                                                />
                                            </ListItem>
                                        );
                                    })}
                            </List>
                        </Box>

                        <Table size="small">
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={6}
                                        count={
                                            this.state.historicalProjects.filter(
                                                (e) =>
                                                    e.name
                                                        .toLowerCase()
                                                        .includes(this.state.historicalProjectsFilter.toLowerCase()) ||
                                                    e.id.includes(this.state.historicalProjectsFilter)
                                            ).length
                                        }
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.historicalProjectsCurrentPage}
                                        onPageChange={this.historicalProjectsChangePage}
                                        ActionsComponent={TablePaginationActions}
                                    ></TablePagination>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TabPanel>
                </LoadingOverlay>
            </Fragment>
        );
    }
}

class ListItemPrimarySecondary extends Component {
    render() {
        if (this.props.element.hasProperties) {
            return (
                <ListItemText>
                    <strong>{this.props.secondary}</strong> {this.props.primary}
                </ListItemText>
            );
        } else {
            return (
                <ListItemText>
                    <strong style={{ opacity: 0.5 }}>{this.props.secondary}</strong>{" "}
                    <span style={{ opacity: 0.5 }}>{this.props.primary}</span>
                </ListItemText>
            );
        }
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box paddingTop={2} paddingBottom={1}>
                {children}
            </Box>
        </Typography>
    );
}

export default ProjectSelect;
