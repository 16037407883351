import { Typography } from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import React, { Component } from "react";
import Flag from "react-world-flags";
import BasinNodes from "./BasinNodes";

class CountryNode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countries: props.countries,
            countryBasinsInfo: props.countryBasinsInfo,
            countryBasins: props.countryBasins,
            basinFields: props.basinFields,
            basinReservoirs: props.basinReservoirs,
        };
    }

    render() {
        let cnt = this.props.country;

        return (
            <TreeItem
                nodeId={"CNT_" + cnt.coCode}
                key={cnt.coCode}
                label={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                        }}
                    >
                        <Flag code={cnt.coCode} height="16" width="24" style={{ marginRight: "1rem" }} />
                        <Typography variant="body2" component="span">
                            {cnt.countryName}
                        </Typography>
                    </div>
                }
            >
                {cnt.hasFieldsOrReservoirs ? (
                    <BasinNodes
                        country={cnt}
                        countryBasinsInfo={this.state.countryBasinsInfo}
                        countryBasins={this.state.countryBasins}
                        basinFields={this.state.basinFields}
                        basinReservoirs={this.state.basinReservoirs}
                        fieldCheckedChanged={this.props.fieldCheckedChanged}
                        reservoirCheckedChanged={this.props.reservoirCheckedChanged}
                        checkedFields={this.props.checkedFields}
                        checkedReservoirs={this.props.checkedReservoirs}
                        selectedProject={this.props.selectedProject}
                        editingMode={this.props.editingMode}
                    />
                ) : (
                    <Typography variant="caption" component="span" style={{ opacity: 0.5 }}>
                        No properties selected
                    </Typography>
                )}
            </TreeItem>
        );
    }
}

export default CountryNode;
