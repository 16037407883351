import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from "@material-ui/icons/List";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from "@material-ui/icons/People";
import SearchIcon from "@material-ui/icons/Search";
import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { version } from "../../package.json";

interface HeaderProps {
  isSignedIn: boolean;
  isAdmin: boolean;
  onChangeColorSchemeClick: () => void;
  onLogoutClick: () => void;
}

export default function Header(props: HeaderProps): ReactElement {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <AppBar position="static" elevation={1}>
      <Toolbar>
        <Link to="/">
          <img
            src={process.env.PUBLIC_URL + "/dm_logo.png"}
            alt=""
            style={{
              height: "52px",
              verticalAlign: "middle",
              paddingRight: "2rem",
            }}
          />
        </Link>
        <Typography
          variant="subtitle2"
          color="inherit"
          noWrap
          style={{ flexGrow: 1 }}
        >
          D&amp;M Project Properties v{version}
        </Typography>
        <Tooltip title="Change Color Scheme">
          <IconButton onClick={props.onChangeColorSchemeClick}>
            <ColorLensIcon />
          </IconButton>
        </Tooltip>
        {props.isSignedIn && (
          <Button
            endIcon={<MenuIcon />}
            variant="outlined"
            onClick={openDrawer}
          >
            Menu
          </Button>
        )}
      </Toolbar>
      <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
        <List style={{ width: 250 }}>
          <ListItem
            button
            component={Link}
            to="/"
            key="home"
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/search"
            key="search"
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Search" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/projects"
            key="projects"
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Projects" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/fields"
            key="fields"
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Fields" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/reservoirs"
            key="reservoirs"
            onClick={closeDrawer}
          >
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Reservoirs" />
          </ListItem>
          {props.isAdmin && (
            <ListItem
              button
              component={Link}
              to="/users"
              key="users"
              onClick={closeDrawer}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="User Management" />
            </ListItem>
          )}
        </List>
        <Divider />
        <List>
          <ListItem button key="logout" onClick={props.onLogoutClick}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </AppBar>
  );
}
