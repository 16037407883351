export function formatDateOnly(date: string): string {
  if (date) {
    return new Date(date).toLocaleDateString();
  }

  return "-";
}

export function dateFromString(s: string): Date | undefined {
  if (s) {
    return new Date(s);
  }
  return undefined;
}

export function saveBlob(blob: Blob, filename: string): void {
  const a: HTMLAnchorElement = document.createElement("a");
  a.download = filename;
  a.href = window.URL.createObjectURL(blob);
  a.dispatchEvent(
    new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    })
  );
  a.remove();
}
