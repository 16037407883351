import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { ReactElement, useEffect, useState } from "react";
import { User, UserType } from "../types";

interface UserDialogProps {
  open: boolean;
  user: User | undefined;
  onConfirm: (email: string, type: UserType, isActive: boolean) => void;
  onDismiss: () => void;
}

export default function UserDialog(props: UserDialogProps): ReactElement {
  const [email, setEmail] = useState("");
  const [type, setType] = useState(UserType.ADMIN);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (!props.open) return;

    if (props.user) {
      setEmail(props.user.email);
      setType(props.user.userType);
      setIsActive(props.user.userActive);
    } else {
      setEmail("");
      setType(UserType.ADMIN);
      setIsActive(true);
    }
  }, [props.user, props.open]);

  return (
    <Dialog fullWidth maxWidth="xs" open={props.open} onClose={props.onDismiss}>
      <DialogTitle>{props.user ? "Edit user" : "Add new user"}</DialogTitle>

      <DialogContent dividers>
        <TextField
          fullWidth
          variant="standard"
          label="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{ readOnly: Boolean(props.user) }}
          InputLabelProps={{ shrink: true }}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel shrink id="label-user-type">
            User Type
          </InputLabel>
          <Select
            fullWidth
            variant="standard"
            labelId="label-user-type"
            value={type}
            onChange={(e) => setType(e.target.value as UserType)}
          >
            <MenuItem value="ADMIN">ADMIN</MenuItem>
            <MenuItem value="USER">USER</MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          label="Login Enabled"
          control={
            <Checkbox
              color="primary"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
            />
          }
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={() => props.onConfirm(email, type, isActive)}
        >
          Save
        </Button>

        <Button color="primary" onClick={() => props.onDismiss()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
