import {
    Box,
    Checkbox,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { Component } from "react";
import { ApiCallGetDocuments } from "../Utils/ApiCalls";
import LoadingOverlay from "./LoadingOverlay";
import TablePaginationActions from "./TablePaginationActions";

class ProjectDocumentSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authToken: props.authToken,
            selectedProject: props.selectedProject,
            documents: [],
            documentsLoadError: false,

            loadingActive: true,

            tablePage: 0,
            rowsPerPage: 15,

            documentFilter: "",
        };

        this.getDocuments = this.getDocuments.bind(this);
        this.getDocumentsError = this.getDocumentsError.bind(this);
        this.tableChangePage = this.tableChangePage.bind(this);
        this.setDocumentFilter = this.setDocumentFilter.bind(this);
        this.checkboxCheckedChanged = this.checkboxCheckedChanged.bind(this);
        this.pushSelectedDocumentsToParent = this.pushSelectedDocumentsToParent.bind(this);

        ApiCallGetDocuments(props.authToken, props.selectedProject, this.getDocuments, this.getDocumentsError);
    }

    getDocuments(data) {
        let documents = [];

        let selProj = this.state.selectedProject;

        let selected = data.selectedDocuments;

        for (let d of data.allDocuments) {
            let doc = {
                name: d,
                checked: d.startsWith(selProj + " ") || selected.filter((e) => e === d).length > 0,
            };

            documents.push(doc);
        }

        this.setState({
            documents: documents,
            loadingActive: false,
            documentsLoadError: false,
        });

        this.pushSelectedDocumentsToParent();
    }

    getDocumentsError() {
        this.setState({
            documents: [],
            loadingActive: false,
            documentsLoadError: true,
        });
    }

    tableChangePage(event, newPage) {
        this.setState({ tablePage: newPage });
    }

    setDocumentFilter(e) {
        this.setState({ documentFilter: e.target.value, tablePage: 0 });
    }

    checkboxCheckedChanged(e) {
        let id = e.target.id;
        let checked = e.target.checked;

        let documents = this.state.documents;

        for (let d of documents) {
            if (d.name === id) {
                d.checked = checked;

                break;
            }
        }

        this.setState({
            documents: documents,
        });

        this.pushSelectedDocumentsToParent();
    }

    pushSelectedDocumentsToParent() {
        let documents = [];
        for (let d of this.state.documents) {
            if (d.checked) documents.push(d.name);
        }

        this.props.selectedDocumentsHandler(documents);
    }

    getRenderComponent() {
        if (this.state.documentsLoadError === true) {
            return this.getErrorComponent();
        } else {
            return this.getDocumentListComponent();
        }
    }

    getDocumentListComponent() {
        return (
            <>
                <Box m={1}>
                    <TextField
                        fullWidth
                        label="Filter documents"
                        size="small"
                        variant="outlined"
                        margin="dense"
                        value={this.state.documentFilter}
                        onChange={this.setDocumentFilter}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                <Table stickyHeader size="small">
                    <TableBody>
                        {this.state.documents
                            .filter((e) => e.name.toLowerCase().includes(this.state.documentFilter.toLowerCase()))
                            .slice(
                                this.state.tablePage * this.state.rowsPerPage,
                                this.state.tablePage * this.state.rowsPerPage + this.state.rowsPerPage
                            )
                            .map((document) => {
                                return (
                                    <TableRow key={document.name}>
                                        <TableCell>
                                            <Checkbox
                                                id={document.name}
                                                onChange={this.checkboxCheckedChanged}
                                                checked={document.checked}
                                                style={{
                                                    padding: "0",
                                                    width: "16px",
                                                    height: "16px",
                                                }}
                                            ></Checkbox>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="caption" component="span">
                                                {document.name}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={2}
                                count={
                                    this.state.documents.filter((e) =>
                                        e.name.toLowerCase().includes(this.state.documentFilter.toLowerCase())
                                    ).length
                                }
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.tablePage}
                                onPageChange={this.tableChangePage}
                                ActionsComponent={TablePaginationActions}
                            ></TablePagination>
                        </TableRow>
                    </TableFooter>
                </Table>
            </>
        );
    }

    getErrorComponent() {
        return (
            <Alert severity="error" variant="outlined">
                <AlertTitle>Error</AlertTitle>Failed to load list of documents from server. Please check VPN settings.
            </Alert>
        );
    }

    render() {
        return (
            <LoadingOverlay active={this.state.loadingActive} spinner text="Please wait...">
                {this.getRenderComponent()}
            </LoadingOverlay>
        );
    }
}

export default ProjectDocumentSelect;
