import { Box, Button, Container, Paper, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";

interface UnauthorizedProps {
  onLogoutClick: () => void;
}

export default function Unauthorized(props: UnauthorizedProps): ReactElement {
  return (
    <Container component="main" maxWidth="xs">
      <Paper style={{ padding: "1rem", marginTop: "3rem" }} elevation={6}>
        <Typography align="center">Your user account is locked.</Typography>
        <Box m="3rem" />
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={props.onLogoutClick}
        >
          Logout
        </Button>
      </Paper>
    </Container>
  );
}
