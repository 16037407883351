import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import { Alert } from "@material-ui/lab";
import { ReactElement, useEffect, useState } from "react";
import { apiGetProjectReports } from "../api";
import { Report } from "../types";

interface ProjectReportsDialogProps {
  authToken: string;
  projectNo: string;
  open: boolean;
  onClose: () => void;
}

export default function ProjectReportsDialog(
  props: ProjectReportsDialogProps
): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState<Report[]>([]);

  useEffect(() => {
    if (!props.projectNo) {
      setReports([]);
      return;
    }

    setIsLoading(true);
    apiGetProjectReports(props.authToken, props.projectNo)
      .then((data) => {
        setIsLoading(false);
        setReports(data);
      })
      .catch(() => {
        setIsLoading(false);
        setReports([]);
      });
  }, [props.authToken, props.projectNo]);

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Project {props.projectNo} Reports</DialogTitle>
      <DialogContent dividers>
        <Backdrop open={isLoading} style={{ zIndex: 100 }}>
          <CircularProgress />
        </Backdrop>

        {!isLoading && reports.length === 0 && (
          <Alert severity="info">No reports found.</Alert>
        )}

        <List dense>
          {reports.map((row) => (
            <ListItem
              button
              component="a"
              target="_blank"
              rel="noopener"
              key={row.reportName}
              href={row.reportLink}
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary={row.reportName} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
