import { Typography } from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import React, { Component } from "react";
import FieldNodes from "./FieldNodes";
import ReservoirNodes from "./ReservoirNodes";

class BasinNodes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countryBasinsInfo: props.countryBasinsInfo,
            countryBasins: props.countryBasins,
            basinFields: props.basinFields,
            basinReservoirs: props.basinReservoirs,
        };

        this.getBasinNode = this.getBasinNode.bind(this);
    }

    getBasinNodeId(basin) {
        return basin === undefined || basin.barCode === undefined
            ? Math.random().toString()
            : "BAS_" + basin.barCode.toString();
    }

    getBasinNode(basin) {
        let info = this.state.countryBasinsInfo[basin.barCode];

        if (info !== undefined) {
            if (info.fieldCount === 0 && info.reservoirCount === 0)
                return (
                    <Typography variant="body2" component="span">
                        <span style={{ opacity: "0.5" }}>{basin.barName}</span>
                    </Typography>
                );
        }

        if (info !== undefined && this.props.editingMode) {
            if (info.basinHasProperties_Fields === true || info.basinHasProperties_Reservoirs === true) {
                return (
                    <Typography variant="body2" component="span">
                        <strong>{basin.barName}</strong>
                    </Typography>
                );
            }
        }

        return (
            <Typography variant="body2" component="span">
                {basin.barName}
            </Typography>
        );
    }

    render() {
        return this.state.countryBasins[this.props.country.coCode].map((basin) => {
            return (
                <TreeItem
                    nodeId={this.getBasinNodeId(basin)}
                    key={this.getBasinNodeId(basin)}
                    label={this.getBasinNode(basin)}
                >
                    <FieldNodes
                        basin={basin}
                        countryBasinsInfo={this.state.countryBasinsInfo}
                        basinFields={this.state.basinFields}
                        fieldCheckedChanged={this.props.fieldCheckedChanged}
                        checkedFields={this.props.checkedFields}
                        selectedProject={this.props.selectedProject}
                        editingMode={this.props.editingMode}
                    />

                    <ReservoirNodes
                        basin={basin}
                        countryBasinsInfo={this.state.countryBasinsInfo}
                        basinReservoirs={this.state.basinReservoirs}
                        reservoirCheckedChanged={this.props.reservoirCheckedChanged}
                        checkedReservoirs={this.props.checkedReservoirs}
                        selectedProject={this.props.selectedProject}
                        editingMode={this.props.editingMode}
                    />
                </TreeItem>
            );
        });
    }
}

export default BasinNodes;
