const ApiPrefix = process.env.REACT_APP_API_URL_PREFIX;

function invokeJsonCall(api, method, token, onComplete) {
    let invalid = false;

    fetch(ApiPrefix + api, {
        method: method,

        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((res) => {
            if (res.status >= 400) {
                invalid = true;
                return res.text();
            }

            return res.json();
        })
        .then((data) => {
            onComplete(invalid, data);
        });
}

function invokeJsonCallWithBody(api, method, token, body, onComplete) {
    let invalid = false;

    fetch(ApiPrefix + api, {
        method: method,

        body: JSON.stringify(body),

        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((res) => {
            if (res.status >= 400) {
                invalid = true;
                return res.text();
            }

            return res.json();
        })
        .then((data) => {
            onComplete(invalid, data);
        });
}

export function ApiCallCountries(token, projectId, editingMode, addNewPropertiesMode, searchText, onComplete) {
    let api = "";
    let searchEncoded = encodeURIComponent(searchText);

    // editing mode -> read only filtered countries
    if (editingMode === true) api = "countries?projectId=" + projectId + "&editing=true&searchtext=" + searchEncoded;

    // add new properties mode -> read all countries
    if (addNewPropertiesMode === true) api = "countries?searchtext=" + searchEncoded;

    // editing mode is false -> read only filtered countries
    if (editingMode === false) api = "countries?projectId=" + projectId + "&editing=false&searchtext=" + searchEncoded;

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallBasins(token, countryCode, projectId, editingMode, searchText, onComplete) {
    let searchEncoded = encodeURIComponent(searchText);
    let api =
        "basins?countryCode=" +
        countryCode +
        "&projectId=" +
        projectId +
        "&editing=" +
        editingMode +
        "&searchtext=" +
        searchEncoded;

    function completeHandler(invalid, data) {
        onComplete(invalid, countryCode, data);
    }

    invokeJsonCall(api, "GET", token, completeHandler);
}

export function ApiCallGetProjectDocuments(token, projectId, onComplete) {
    let api = "documents/projectdocuments?projectname=" + projectId;

    function completeHandler(invalid, data) {
        onComplete(data);
    }

    invokeJsonCall(api, "GET", token, completeHandler);
}

export function ApiCallDeleteDocument(token, documentId) {
    let api = "documents/deletedocument?documentId=" + documentId;

    function completeHandler(invalid, data) {
        // TODO
    }

    invokeJsonCall(api, "DELETE", token, completeHandler);
}

export function ApiCallGetDocuments(token, projectId, onComplete, onError) {
    let api = "documents/get?projectId=" + projectId;

    function completeHandler(invalid, data) {
        if (invalid) {
            onError();
        } else {
            onComplete(data);
        }
    }

    invokeJsonCall(api, "GET", token, completeHandler);
}

export function ApiCallSetProjectDocuments(token, data, onComplete) {
    let api = "documents/projectdocuments";

    function completeHandler(invalid, data) {
        if (onComplete) onComplete(data);
    }

    invokeJsonCallWithBody(api, "PUT", token, data, completeHandler);
}

export function ApiCallExportFields(projectId) {
    let api = "export/fields?projectId=" + projectId;

    window.open(ApiPrefix + api, "_blank");
}

export function ApiCallExportReservoirs(projectId) {
    let api = "export/reservoirs?projectId=" + projectId;

    window.open(ApiPrefix + api, "_blank");
}

export function ApiCallAdvancedSearchReports(token, projNo, onComplete) {
    let api = "search/reports?projNo=" + projNo;

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallAllClients(token, onComplete) {
    let api = "clients/all";

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallClientsForCountry(token, country, onComplete) {
    let api = "clients/countryclients?country=" + country;

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallCountriesForClient(token, client, onComplete) {
    let api = "clients/clientcountries?client=" + client;

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallCheckPropertyCount(token, projectId, fields, onComplete) {
    let api = "export/checkpropertycount?projectId=" + projectId + "&fields=" + fields;

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallGetProjects(token, onComplete) {
    let api = "projectinfo";

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallGetProjectFields(token, projectId, onComplete) {
    let api = "projectFields/list?projectId=" + projectId;

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallGetProjectReservoirs(token, projectId, onComplete) {
    let api = "projectReservoirs/list?projectId=" + projectId;

    invokeJsonCall(api, "GET", token, onComplete);
}

export function ApiCallSaveProjectProperties(token, projectId, fields, reservoirs, onComplete) {
    let api = "projectProperties";

    let method = "PUT";

    let body = {
        projectId: projectId,
        fields: fields.map((fld) => fld.id),
        reservoirs: reservoirs.map((res) => res.id),
    };

    let invalid = false;

    fetch(ApiPrefix + api, {
        method: method,
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((res) => {
            if (res.status >= 400) {
                invalid = true;
            }

            return res.text();
        })
        .then((data) => {
            onComplete(invalid, data);
        });
}

export function ApiCallUploadFile(token, projectId, type, file, onComplete) {
    let api = "uploadfile?type=" + type + "&projectId=" + projectId;

    let method = "POST";

    const body = new FormData();
    body.append("file", file);

    let invalid = false;

    fetch(ApiPrefix + api, {
        method: method,
        body: body,
        headers: {
            Authorization: "Bearer " + token,
        },
    })
        .then((res) => {
            if (res.status >= 400) {
                invalid = true;
            }

            return res.json();
        })
        .then((data) => {
            onComplete(invalid, data);
        });
}
