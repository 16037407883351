import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import React from "react";
import { ReactElement } from "react";
import { Field } from "../types";

interface FieldInfoDialogProps {
  open: boolean;
  field: Field;
  onClose: () => void;
}

export default function FieldInfoDialog(
  props: FieldInfoDialogProps
): ReactElement {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
      <DialogTitle>Field {props.field.id} Properties</DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={4}>
            <TextField
              label="Field Code"
              value={props.field.code}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="Field Name"
              value={props.field.name}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <TextField
          label="Alias"
          value={props.field.alias}
          variant="standard"
          margin="dense"
          fullWidth
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          label="Location"
          value={props.field.location}
          variant="standard"
          margin="dense"
          fullWidth
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          label="BAR Name"
          value={props.field.barName}
          variant="standard"
          margin="dense"
          fullWidth
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />

        <Grid container>
          <Grid item xs={6}>
            <TextField
              label="Added by"
              value={props.field.addedBy}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Added on"
              value={props.field.addedOn}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <TextField
              label="Updated by"
              value={props.field.updatedBy}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Updated on"
              value={props.field.updatedOn}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <TextField
          label="Comment"
          value={props.field.comment}
          variant="standard"
          margin="dense"
          fullWidth
          multiline
          minRows={3}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
