import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import React from "react";
import { ReactElement } from "react";
import { Reservoir } from "../types";

interface ReservoirInfoDialogProps {
  open: boolean;
  reservoir: Reservoir;
  onClose: () => void;
}

export default function ReservoirInfoDialog(
  props: ReservoirInfoDialogProps
): ReactElement {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
      <DialogTitle>Reservoir {props.reservoir.id} Properties</DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={4}>
            <TextField
              label="Reservoir Code"
              value={props.reservoir.code}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="Reservoir Name"
              value={props.reservoir.name}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <TextField
          label="Alias"
          value={props.reservoir.alias}
          variant="standard"
          margin="dense"
          fullWidth
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          label="BAR Name"
          value={props.reservoir.barName}
          variant="standard"
          margin="dense"
          fullWidth
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />

        <Grid container>
          <Grid item xs={6}>
            <TextField
              label="Era"
              value={props.reservoir.era}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Period"
              value={props.reservoir.period}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <TextField
          label="Lithology"
          value={props.reservoir.lithology}
          variant="standard"
          margin="dense"
          fullWidth
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          label="Product"
          value={props.reservoir.product}
          variant="standard"
          margin="dense"
          fullWidth
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />

        <Grid container>
          <Grid item xs={6}>
            <TextField
              label="Added by"
              value={props.reservoir.addedBy}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Added on"
              value={props.reservoir.addedOn}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <TextField
              label="Updated by"
              value={props.reservoir.updatedBy}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Updated on"
              value={props.reservoir.updatedOn}
              variant="standard"
              margin="dense"
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <TextField
          label="Comment"
          value={props.reservoir.comment}
          variant="standard"
          margin="dense"
          fullWidth
          multiline
          minRows={3}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
